import React,  {useState} from 'react';



const ScrollButton = () => {

    const [visible, setVisible] = useState(false);
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 100){
          setVisible(true)
        } 
        else if (scrolled <= 300){
          setVisible(false)
        }
      };
      
      const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
      };
      
      window.addEventListener('scroll', toggleVisible);

return (
    <>
<div id="back-top" onClick={scrollToTop} 
     style={{display: visible ? 'inline' : 'none'}}>
  <a href="#top" className="button-style-1" >
    <i className="fa fa-angle-up" />{" "}
  </a>
</div>
    </>   
);
}

export default ScrollButton;
