const ACCOUNT_DETAILS = {
  /*======================================
  ******** BASIC PORTAL DETAILS **********
  =======================================*/
  portalName: "Dev Bhoomi",
  portalTestUrl: "https://devbhoomigroupofinstitutions-vrmyca9h.lsqportal-test.com",
  portalLiveUrl: "https://admissions.dbuu.ac.in",
  poweredBy: "https://www.leadsquared.com/",

  /*======================================
  ************* ACTIVITY DATA ************
  =======================================*/

  activityData: {
    applicationForm: {
      eventCode: "224",
    },
  },

  /*======================================
  ************** FORMS DATA **************
  =======================================*/
  formsData: {
    lsqAppUrl: "https://in21.leadsquared.com",
    lsqApiServiceUrl: "https://portalapi-in21.leadsquared.com/api/Form",
    lsqFormUrl:
      "https://dhx9mmhpfsala.cloudfront.net/cdn/externalforms/r21/js/lsq.form.js?v=65.3.1.5",
    lsqAuthKey:
      "SUtlSGMyUll2MmpINDE0c0QxZXkxSW5RZDBFTHFBb3ptMGF4TGFFdFhuZmlTTW15cSs0aVNSWXJlOEZ6WXNqQVlrWmRUMkhYc0Z5dDc1YSsxT2JhUEVTQzlGK3RTRXloSWdHcFBZbTNFTlJ3ZXZLelVTZHhnbWpNMGtOYmUxZzlmdVlZV0tsVUZ5RUZTSHpLNCtkMmpsWFVqOGNNOWVmZm1rR0VnTnlSbEFyR2ZUdjZqWVZRQWlmamxNdFVnNy94d0ZiMVJtOVg0WC9Mazc3cnUrK2E4QT09",
    lsqFormThemeColor: "#CA2F30",

    registrationForm: {
      // PRE LOGIN FORM
      // formId: "c978862c-5bee-11ec-a50e-02388b799ec6", //prod
      formId: "7ecc95b8-2c72-407b-bdf1-e3739ecc01e0", //test
      formThemeColor: "#0A2B56",
    },

    applicationform: {
      // POST LOGIN FORM
      gradformId: "3dda3fdc-0ddb-4608-9ddc-066063ce7cb2",
      gradProcessId: "7a12ff9a-60d5-11ed-bc2f-0295f36172a4",
      gradAdmissionFormId: "f2cabb08-4146-400e-8ea8-ffda96ee2101",
      gradAdmissionProcessId: "3684981a-b1cb-4ba7-a974-dfa8cad5b9bf",
      phdFormId: "2714a088-b19e-409f-aafc-f4b335b6db85",
      phdProcessId: "58223675-60d5-11ed-bc2f-0295f36172a4",
      bamsFormId: "185ffc6c-c759-4019-8381-971c7fe4ebdc",
      bamsProcessId: "fc8c874a-60d4-11ed-bc2f-0295f36172a4",
    },

    courseFeePaymentForm: {
      // POST LOGIN FORM
      formId: "6eb7b676-6bec-4cb1-b7eb-6b5b8fb4d79e",
      processId: "efd57153-d6ae-4d66-a5cf-c5e3598a7a1a",
    },

    editProfileForm: {
      // POST LOGIN FORM
      formId: "59231fc2-5bec-11ec-a50e-02388b799ec6",
      processId: "",
    },
  },
};

export { ACCOUNT_DETAILS };
