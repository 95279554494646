import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import { Radio } from "antd";
import "./style.css";
import EmailPassword from "./emailPassword";
import EmailOTP from "./emailOTP"
// import MobileOTP from "./mobileOTP";
import MobileOTP from "./mobileOTP";
import "./mobileOTPStyle.css";


const Login = (props) => {
  const [value, setValue] = useState(1);

  // const history = useNavigate();
  const history = useHistory();
  const onChange = (e) => {
    // console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  return (
    <>
      <div className="form-skeleton" style={{paddingTop: "80px"}}>
        <div className="login-heading">
          <p className="login-title">EXISTING USER?</p>
          {/* <p className="login-caption">
            Create your profile, it will only take a minute.
          </p> */}
        </div>
        <Radio.Group onChange={onChange} value={value} className="loginToggle">
          <Radio value={1} style={{color:"white"}} >Mobile</Radio>
          <Radio value={2} style={{color:"white"}}>Email</Radio>
        </Radio.Group>
        {value === 1 ? <MobileOTP /> : <EmailOTP />}

      </div>
    </>
  );
};

export default Login;





