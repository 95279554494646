import { Menu, Dropdown as Select } from 'antd';
import 'antd/dist/antd.css';
import { CgHome, CgPhone, CgProfile, CgLogOut } from "react-icons/cg";

import styles from "./style.module.css";

const Dropdown = ({ children, options }) => {
  const getIcon = (type) => {
    const defaultStyles = { alignSelf: 'center', marginRight: 6 };

    switch (type) {
      case "Contact Us":
        return <CgPhone color="grey" size={"14"} style={defaultStyles} />;
      case "Dashboard":
        return <CgHome color="grey" size={"14"} style={defaultStyles} />;
      case "Profile":
        return <CgProfile color="grey" size={"14"} style={defaultStyles} />
      default:
        return <CgLogOut color="grey" size={"14"} style={defaultStyles} />;
    }
  }

  const menu = (
    <Menu>
      {options.map((option, index) => {
        const { label, clickHandler } = option;
        return (
          <Menu.Item key={index} style={{ fontSize: 16 }}>
            <div className={styles.menuItemContainer}>
              {getIcon(label)}
              <span onClick={clickHandler}>{label}</span>
            </div>
          </Menu.Item>
        )
      })}
    </Menu>
  );

  return (
    <Select overlay={menu} trigger={['click']} placement="bottomRight">
      <div>
        {children ?? "Dropdown"}
      </div>
    </Select>
  )
}

export default Dropdown