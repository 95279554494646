const ERROR_MSG = {
  REQUIRED: "Required",
  PASSWORD_PATTERN:
    "Password must have minimum 4 & Maximum 20 characters. Must contain at least one upper case letter, one lower case letter, and one number .",
  PASSWORD_MISMATCH: "Your password and confirmation password do not match",
  SERVER_ERROR: "Something went wrong. Please try again.",
  ALREADY_LOGIN: "You are Already Logged in",
  DATA_FETCH_FAIL:"There was some error fetching the data. Please refresh the page and try again"
};

const FORM_PLACEHOLDER = {
  FNAME: "Enter First Name",
  LNAME: "Enter Last Name",
  NAME: "Enter  Name",
  EMAIL: "Enter Email Address",
  PASSWORD: "Enter Password",
  CONFIRM_PASSWORD: "Re-enter the password",
  PHONE_NUMBER: "Enter Phone Number",
  RESET_PASSWORD: "Enter New Password",
  RESET_CONFIRM_PASSWORD: "Confirm New Password",
};


export { ERROR_MSG, FORM_PLACEHOLDER };