const WRAPPER_LAPP_URLS = {
  V2_API_WRAPPER:
    "https://lapps-in21.leadsquared.com/executebylapptoken?name=da_40347_7c87ca8f&stage=Live&xapikey=47d0ccd702814fb8b9de6d61e59ca1f1",

  PORTAL_SIGN_IN_API_WRAPPER:
    "https://lapps-in21.leadsquared.com/executebylapptoken?name=da_40347_67a480f1&stage=Live&xapikey=75ca362e6f4b422cb293c41de374bc4f",

  V2_API_BASE_URL: "https://portalapi-in21.leadsquared.com",
};

export { WRAPPER_LAPP_URLS };
