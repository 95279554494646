import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";

import photo2 from "../../../images/photo-2.png"
import photo3 from "../../../images/photo-3.png"
import four from "../../../images/4000+text.png"
import two from "../../../images/20text.png"
import one from "../../../images/100+text.png"
import scholar from "../../../images/scholarship-logo.png"
import play from "../../../images/pakybtn.png"

const FollowStepsSection = () => {
  return (
    <section className="devsecall devfive">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        {" "}
        <div className="unjkhead">
          <span>Enjoy</span>#CampusLife@DBUU
        </div>
        <div className="immaibx">
          <div className="leimgb">
            <img src="https://f2.leadsquaredcdn.com/t/t20190508183611/content/common/images/photo-1.png" alt="" className="firstimg" />
            <div className="conox">
              <img src={four} alt="" />
              <span>Students on campus</span>
            </div>
          </div>
          <div className="righx">
            <div className="imgox">
              <img src={photo2} alt="" className="seconea ones" />
              <div className="txtbxc">
                <img src={two} alt="" />
                <span>
                  Student
                  <br />
                  activity clubs
                </span>
              </div>
            </div>
            <div className="imgox">
              <img src={photo3} alt="" className="seconea wtwos" />
              <div className="txtbxc">
                <img src={one} alt="" />
                <span>
                  Annual co-curricular and
                  <br />
                  extracurricular events
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row nnebox">
      <div className="col-md-12">
        {" "}
        <div className="abxr">
          Offered <strong>Disciplines</strong>
        </div>
        <div className="leabox">
          <ul>
            <li> Engineering</li>
            <li>Paramedical</li>
            <li>Computer Applications</li>
            <li>Optometry</li>
            <li>Animation and Graphics</li>
            <li>Hospitality</li>
            <li> Pharmacy</li>
            <li>Humanities</li>
            <li>Architecture and Planning</li>
            <li>Applied Sciences</li>
            <li>Fashion Design</li>
            <li>Agriculture</li>
            <li>Nursing</li>{" "}
          </ul>
        </div>
        <div className="riboxa">
          <img src={scholar} alt="" className="ilobx" />
          <a href="#" className="abcd">
            Apply now <img src={play} alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

  );
};

export default FollowStepsSection;
