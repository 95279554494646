import React from "react";
import Spinner from "react-bootstrap/Spinner";

const Button = ({ disabled, type, className, text, status, handleClick, title }) => {
  return (
    <button
      disabled={disabled}
      type={type}
      className={className}
      title={title}
      onClick={handleClick}
    >
      {status ? (
        <span>Loading...</span>
      ) : (
        text
      )}
    </button>
  );
};

export default Button;
