const CARD_THEMES = {
  "orange": {
    cardBackground: "#fffdf0",
    cardBorder: "#ffdebe",
    cardLeftBorder: "#ffdebe",
    badgeBackground: "#ffcd89",
    badgeText: "#8e5200"
  },
  "blue": {
    cardBackground: "#fff",
    cardBorder: "#DDD",
    cardLeftBorder: "#CCE6FF",
    badgeBackground: "#CCE6FF",
    badgeText: "#4A6B8B"
  },
  "yellow": {
    cardBackground: "#fff",
    cardBorder: "#DDD",
    cardLeftBorder: "#FFEEB5",
    badgeBackground: "#FFEEB5",
    badgeText: "#6A5200"
  },
  "green": {
    cardBackground: "#fff",
    cardBorder: "#DDD",
    cardLeftBorder: "#D5F0D0",
    badgeBackground: "#C8F4B8",
    badgeText: "#4a8b58"
  },
  "red": {
    cardBackground: "#fff",
    cardBorder: "#DDD",
    cardLeftBorder: "#ffd6d6",
    badgeBackground: "#ffd6d6",
    badgeText: "#8b4a4a"
  }
}

export default CARD_THEMES;