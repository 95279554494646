import Routes from "./routes"
import ErrorBoundary from "./components/ErrorBoundary"
import "./css/globalStyles.css"
import './App.css';

function App() {
  return (
    <div className="App">
      <ErrorBoundary>
        <Routes />
      </ErrorBoundary>
    </div>
  );
}

export default App;
