import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import AuthHandler from "../utility";
import PreLoginRoutes from "./preLoginRoutes";
import PostLoginRoutes from "./postLoginRoutes";
import { API_GET_LEAD_AUTO_LOGIN } from "../api";
import { LoadingScreen } from "../components/Loader";

// Creating Global Context
export const GLOBAL_CONTEXT = React.createContext();

const Routes = () => {
  // Global State accross all components
  const [globalContext, setGlobalContext] = useState({
    loggedIn: false,
  });
  const [loader, setLoader] = useState(true);

  const { IsAuthSessionInfoCookiePresent } = AuthHandler;

  // Checking Cookie (User Logged In or Not)
  useEffect(() => {
    IsAuthSessionInfoCookiePresent() &&
      setGlobalContext((globalContext) => ({
        ...globalContext,
        loggedIn: true,
      }));
  }, [IsAuthSessionInfoCookiePresent]);

  useEffect(() => {
    if (!IsAuthSessionInfoCookiePresent()) {
      loginWithAuth();
    } else {
      setLoader(false);
    }
  }, [window.location.search]);

  const loginWithAuth = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const leadID = queryParams.get("leadID") ?? "";
    const authkey = queryParams.get("authkey") ?? "";

    if (!IsAuthSessionInfoCookiePresent() && leadID && authkey) {
      const payload = {
        LeadId: leadID,
        RetrieveCFSFields: true,
        FlattenCFSFields: true,
      };

      API_GET_LEAD_AUTO_LOGIN(payload, authkey)
        .then(function (response) {
          const { data } = response;
          const { ProspectID } = data;
          if (response.status !== 200) {
            console.log(response.statusText);
            window.location.href = `/login`;
          } else {
            const authSessionInfo = {
              isAuthenticated: true,
              leadId: ProspectID,
            };

            AuthHandler.SetAuthSessionInfoCookie(
              authSessionInfo,
              setGlobalContext
            );
            setLoader(false);

            const queryParams = window.location.search ?? "";
            window.location.href = `/dashboard/${queryParams}`;
          }
        })
        .catch(function (error) {
          console.log(error);
          setLoader(false);
          const queryParams = window.location.search ?? "";
          window.location.href = `/login/${queryParams}`;
        });
    } else {
      setLoader(false);
    }
  };

  return (
    <GLOBAL_CONTEXT.Provider value={{ globalContext, setGlobalContext }}>
      <BrowserRouter>
        {IsAuthSessionInfoCookiePresent() ? (
          <PostLoginRoutes />
        ) : loader ? (
          <LoadingScreen />
        ) : (
          <PreLoginRoutes />
        )}
      </BrowserRouter>
    </GLOBAL_CONTEXT.Provider>
  );
};

export default Routes;
