import React from 'react';
import logo from "../../images/logo.png";

import styles from "./style.module.css";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <>
        <div className={styles.fallBackWrapper}>
          <img src={logo} alt="Error_Image" />
          <div className={styles.errorTitle}>OOPS!</div>
          <div className={styles.message}>We are sorry, the page encountered an error, please try again in a while.</div>
        </div>
      </>
    }

    return this.props.children;
  }
}

export default ErrorBoundary