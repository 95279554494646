import { HeaderBeforeLogin } from "../../components/Header";
import Login from "../../forms/PreLogin/login";
import RegistrationForm from "../../forms/PreLogin/Registration";
import HighlightSection from "./Sections/highlightsSection";
import FeatureSection from "./Sections/featureSection";
import RecruiterSection from "./Sections/recruiterSection";
import FacultSection from "./Sections/facultySection";
import FollowStepsSection from "./Sections/followStepsSection";
import VideoTestimonialSection from "./Sections/videoTestimonialSection";

import ProgramSection from "./Sections/programSection";
import Footer from "./Sections/footer";
import styles from "./style.module.css";
import UnlimitedImg from "../../images/unlited-text.png"
import { GLOBAL_CONTEXT } from "../../routes";
import { useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import ForgotPasswordForm from "../../forms/PreLogin/forgotPassword/index";
import ResetPasswordForm from "../../forms/PreLogin/resetPassword/index";
// import RegistrationForm from "../../forms/PreLogin/Registration/index"
import ScrollButton from "./Sections/scrollButton";

const LandingPage = () => {
  const GLOBAL_DATA = useContext(GLOBAL_CONTEXT);
  const { setGlobalContext } = GLOBAL_DATA;
  const location = useLocation();
  const { pathname } = location;
  console.log(pathname,"pathname");
  return (
    <>
      <div className="sliderbx">
  <img src="https://f2.leadsquaredcdn.com/t/t20190508183611/content/common/images/Banner.png" alt="" className="sliderbx" />
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="toplobx-andm">
        <HeaderBeforeLogin />
        </div>
      </div>
    </div>
    </div>
    <div className="container botcon">
    <div className={styles.subWrapper}>
          <div className={styles.contentWrapper}>
          <div className={styles.lefxtbx}>
          <span>One University</span>
          <img src={UnlimitedImg} alt={"unlimited image"} />
          <span className={styles.bifonts}>opportunities</span>
          </div>
          </div>
          
          <div className={styles.formsWrapper}>
            <div className={styles.registrationWrapper}>
              <RegistrationForm setGlobalContext={setGlobalContext} />
            </div>
            <div className={styles.loginWrapper}>
            {pathname.includes("/resetpassword") ? (
                <ResetPasswordForm />
              ) : pathname === "/forgotpassword" ? (
                <ForgotPasswordForm />
              ) : pathname === "/login" ? (
                <Login />
              ) : (
                <Login />
              )}
            </div>
          </div>
        </div>
    
  </div>
  </div>



      <HighlightSection />
      <FacultSection />
      <ProgramSection />
      <FeatureSection />
      
      <FollowStepsSection />
      
      <Footer />
      {/* <div id="back-top" style={{}}>
  <a href="#top" className="button-style-1">
    <i className="fa fa-angle-up" />{" "}
  </a>
</div> */}
<ScrollButton/>
    </>
  );
};

export default LandingPage;
