import { FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import ImgLocation from "../../images/contact-location.png";
import { DashboardSubHeader } from "../SubHeader/DashboardSubHeader";
import styles from "./style.module.css";

const ContactUs = () => {
  return (
    <>
      <DashboardSubHeader view={"contactUs"}/>
      <div className={styles.parentWrapper}>
        <div className={styles.boxWrapper}>
          <div className={styles.imgWrapper}></div>
          <div className={styles.locationIcon}>
            <img src={ImgLocation} alt={"Icon"} />
          </div>
          <div className={styles.boxContentWrapper}>
            <p className={styles.address}>
              Dev Bhoomi campus, Chakrata Road Navgaon, Manduwala,Dehradun 248007,
              Uttarakhand, India
            </p>
            <p className={styles.phoneText}>
              <div className={styles.phoneIcon}>
                <FaPhoneAlt size="0.5em" />
              </div>
              <Link
                to='#'
                className={styles.link}
                onClick={(e) => {
                    window.location = "tel:0135269424145";
                    e.preventDefault();
                }}
              >
                0135-2694241-45
              </Link> / 
              <Link
                className={styles.link}
                to='#'
                onClick={(e) => {
                    window.location = "tel:18001034049";
                    e.preventDefault();
                }}
              >
                1800 103 4049
              </Link>
            </p>
            <p className={styles.contact}>
              <Link
                className={styles.link}
                to='#'
                onClick={(e) => {
                    window.location = "mailto:info@dbuu.ac.in";
                    e.preventDefault();
                }}
              >
                info@dbuu.ac.in
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>);
};

export default ContactUs;