import React from "react";
import Loader from "react-loader-spinner";
import { Spin, Skeleton } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import 'antd/dist/antd.css';
import "./style.css";

const LoadingScreen = () => {
  return (
    <div className="loader-screen">
      <Loader type="Bars" color="#ffffff" height={100} width={100} />
    </div>
    // <></>
  );
};

const SpinLoader = () => {
  return (<Spin spin indicator={
    <LoadingOutlined style={{
      fontSize: 24,
      color: "red",
      marginTop: 10
    }} />
  } />)
}

const CardLoader = () => {
  return (<Skeleton active
    className="customAntLoaderWrapper" />)
}

export { LoadingScreen, SpinLoader, CardLoader };
