import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { useHistory } from "react-router-dom";
import { AiFillCaretDown } from "react-icons/ai";
import { CgHome } from "react-icons/cg";
import Dropdown from "../Dropdown";
import Logout from "../Logout";
import PentaLogo from "../../images/pentaLogo.png";
import PhoneIcon from "../../images/phone-icon-2.png"
import Logo from "../../images/DashboardLogo.png";
import Profile from "../../images/DashboardProfile.png"
import styles from "./style.module.css";

import { GLOBAL_CONTEXT } from "../../routes";

const defaultStyles = { alignSelf: 'center', marginRight: 6 };

const HeaderBeforeLogin = () => {
  return (
    <div className={styles.BL_ParentWrapper}>
      <div className={styles.BL_LogoWrapper}>
        <img
          src={PentaLogo}
          alt={"Dev Bhoomi Logo"}
          title={"Dev Bhoomi"} />
          <a href={"#"} className={styles.top_number}><img src={PhoneIcon} alt={"Phone Logo"} title={"Dev Bhoomi"} /> Toll-free - 1800-103-4049</a>
      </div>
    </div>
  );
};

const HeaderAfterLogin = () => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const GLOBAL_DATA = useContext(GLOBAL_CONTEXT) || {};
  const { globalContext = {} } = GLOBAL_DATA;
  const { leadData } = globalContext;

  const { FirstName, LastName, ProspectID } = leadData;

  // Dropdown in Desktop [When Clicked on Profile Icon]
  const desktopDropdownOptions = [
    {
      label: "Profile",
      clickHandler: () => {
        window.location.href = (`/profileForm?leadId=${ProspectID}`);
      },
    },
    {
      label: "Contact Us",
      clickHandler: () => history.push("/contactUs"),
    },
    {
      label: <Logout />,
    },
  ];

  // Dropdown in Mobile [When Clicked on Hamburger]
  const mobileDropdownOptions = [
    {
      label: "Dashboard",
      clickHandler: () => window.location.href = ("/dashboard"),
    },
    {
      label: "Profile",
      clickHandler: () => {
        window.location.href = (`/profileForm?leadId=${ProspectID}`);
      },
    },
    {
      label: "Contact Us",
      clickHandler: () => history.push("/contactUs"),
    },
    {
      label: <Logout />,
    },
  ];

  return (
    <div className={styles.AL_ParentWrapper}>
      <div className={styles.AL_LeftSideWrapper}>
        <img
          src={Logo}
          alt={"Dev Bhoomi Logo"}
          title={"Dev Bhoomi Logo"}
          onClick={() => window.location.href = ("/dashboard")}
        />
      </div>

      <div className={styles.AL_RightSideWrapper}>
        <div className={styles.AL_RightSideMobileView}>
          <Dropdown options={mobileDropdownOptions}>
            <GiHamburgerMenu style={{ height: 21, width: 21, verticalAlign: 'middle', color: 'red' }} />
          </Dropdown>
        </div>

        <div className={styles.AL_RightSideDesktopView}>
          {
            (pathname.includes("dashboard") || pathname === "/") ? <></> : (
              <div className={styles.AL_DashboardContainer} onClick={() => window.location.href = ("/dashboard")}>
                <CgHome color="grey" size={"10"} style={defaultStyles} />
                <span>Dashboard</span>
              </div>
            )
          }
          <div className={styles.AL_LogoutWrapper}>
            <div className={styles.AL_UserProfile}>
              <span title="User Name">{`${FirstName} ${LastName ?? ""}`}</span>
              <Dropdown options={desktopDropdownOptions}>
                <span className={styles.AL_ProfileIcon} title="More Options">
                  <img
                    src={Profile}
                    alt={"Profile Icon"}
                    title={"Profile Icon"}
                  />
                  <AiFillCaretDown color="grey" size='12' style={{ marginLeft: 8 }} />
                </span>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { HeaderBeforeLogin, HeaderAfterLogin };
