import React from 'react'
import stateOfHeart from "../../../images/stateoftheart-image.jpg";
import collabImg from "../../../images/colloborations-image.jpg";
import placementImg from "../../../images/placement-image.jpg";

const HighlightSection = (props) => {
  return (
    <>
      {/* <div className="advantages-section">
        <div className="section3-line-bg" />
        <div className="container">
          <div className="row">
            <div className="col-12  col-md-4 text-center">
              <div className="col1">
                <h3>STATE-OF-THE-ART<br />INFRASTRUCTURE</h3>
                <img src={stateOfHeart} alt={"Img1"} />
                <p>
                  Built with top-of-the-line laboratories, tech-enabled libraries, well-equipped studios, construction yards, agricultural landscapes, wifi-enabled classrooms, comfortable hostels and  outdoor sports facilities, DBUU leaves no stone unturned to help students stay ahead in the game.
                </p>
              </div>
            </div>
            <div className="col-12  col-md-4 text-center">
              <div className="col1"><h3>INDUSTRY<br />COLLABORATIONS</h3>
                <img src={collabImg} alt={"img2"} />
                <p>All programs at DBUU are crafted with close sync to the industry. Be it adding certification courses or giving extensive industry training opportunities to students, DBUU tries to bring the industry to the classroom. Seminars, workshops, conferences are norms of the day here</p>
              </div>
            </div>
            <div className="col-12  col-md-4 text-center">
              <div className="col1">
                <h3>INTERNSHIPS &amp; <br />PLACEMENTS</h3>
                <img src={placementImg} alt={"img3"} />
                <p>Students of DBUU are offered internship opportunities during their final semesters at the institute and thereafter, Career Support Team through its mentorship and networking supports every student to help make careers of ther choice.</p></div></div>
          </div>
        </div>
      </div> */}
      <section className="devsecall devone">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="yelobx">Direct admissions open</div>
              <div className="pikbox">
                <div className="bighead">Rs. 5 Crores in Scholarships</div>
                <div className="heline">
                  <span>
                    Start your application today to ensure your scholarship.
                  </span>
                </div>
                <p>
                  Dev Bhoomi Uttarakhand University is driven by the mission to
                  provide quality and affordable education to youth who shall take
                  charge of the nation and the industry in the coming year. To ensure
                  that monetary restrictions don’t let brilliant minds lag behind.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="threebx">
                <div className="btonx">
                  <div className="headingall">
                    DBUU <span>Scholarship</span> <br />
                    Entrance Test
                  </div>
                  <ul className="boxab">
                    <li>
                      Qualification
                      <br />
                      <strong>Candidate must be appearing in Class 12th</strong>
                    </li>
                    <li>
                      Mode of exam
                      <strong>Online</strong>
                    </li>
                    <li>
                      Exam duration
                      <strong>90 Minutes</strong>
                    </li>
                    <li>
                      Exam date
                      <strong>Will declare soon.
                      </strong>
                    </li>
                  </ul>
                </div>
                <div className="btonx">
                  <div className="headingall twc">
                    Merit-based
                    <span>Scholarship</span>
                  </div>
                  <ul className="tbox">
                    <li className="firstbx">
                      <span>Score </span> <span>Scholarship</span>
                    </li>
                    <li className="allbxa">
                      <span>&gt;= 95% - 100%</span> <span>50%</span>
                    </li>
                    <li className="allbxa">
                      <span>&gt;= 90% - &lt; 95%</span> <span>25%</span>
                    </li>
                    <li className="allbxa">
                      <span>&gt;= 80% - &lt; 90%</span> <span>15%</span>
                    </li>
                    <li className="allbxa">
                      <span>&gt;= 75% - &lt; 80%</span> <span>10%</span>
                    </li>
                    
                  </ul>
                </div>
                <div className="btonx blubox">
                  <div className="headingall twc">
                    Other
                    <span>Scholarship</span>
                  </div>
                  <p>
                    Candidates falling in the following categories are eligible for
                    their category’s respective scholarship award(s)
                  </p>
                  <ul className="throbc">
                    
                    <li>
                      <span>All India Quota - 10%<sup>*</sup></span>(for the first year of the
                      course)
                    </li>
                    <li>
                      <span>Girl child - 5%<sup>*</sup></span>(for complete course duration)
                    </li>
                    <li>
                      <span>Wards of army personnel and martyrs - 5%<sup>*</sup></span>
                    </li>
                    <li>
                      <span>Sports personnel - 5%<sup>*</sup></span>(for complete course duration)
                    </li>
                    <li>
                      <span>Early bird applicants - 05%<sup>*</sup></span>(for the first semester)
                    </li>
                  </ul>
                  <span className="trm"><sup>*</sup>Term & Condition : Only on Academic fees</span>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </section>

    </>
  )
}

export default HighlightSection;