import logo1 from "../../../images/recruiter-logo1.png";
import logo2 from "../../../images/recruiter-logo2.png";
import logo3 from "../../../images/recruiter-logo3.png";
import logo4 from "../../../images/recruiter-logo4.png";
import logo5 from "../../../images/recruiter-logo5.png";
import logo6 from "../../../images/recruiter-logo6.png";
import logo7 from "../../../images/recruiter-logo7.png";
import logo8 from "../../../images/recruiter-logo8.png";
import logo9 from "../../../images/recruiter-logo9.png";
import logo10 from "../../../images/recruiter-logo10.png";
import rupeeIcon from "../../../images/rupee-icon.png";
import recIcon from "../../../images/rec-icon.png";
import agreeIcon from "../../../images/agreement-icon.png";
import OwlCarousel from "react-owl-carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { useEffect } from "react";
import $ from 'jquery'

const RecruiterSection = () => {



    
  return (
    <div className="placement-recruiters-section">
      <div className="container">
        <div className="row">
          <div className="col-md-1 col-xs-12"></div>
          <div className="col-md-4 col-xs-12 bordertop">
            <h3>PLACEMENT UPDATE</h3>
            <div className="img-icon">
              <img src={rupeeIcon} width="30" height="30" />
            </div>
            <p>
              <span className="small">Highest CTC Offered</span>
              <br />
              40 Lacs. P.A
            </p>
            <div className="clearfix"></div>

            <div className="img-icon">
              <img src={recIcon} width="30" height="30" />
            </div>
            <p>
              <span className="small">Total number of recruiters</span>
              <br />
              800+
            </p>

            <div className="clearfix"></div>

            <div className="img-icon">
              <img src={agreeIcon} width="30" height="30" />
            </div>
            <p>
              <span className="small">Total offers made</span>
              <br />
              15000+
            </p>
          </div>
          <div className="col-md-1 col-xs-12"></div>

          <div className="col-md-6 col-xs-12">
            <div className="recuiters-testimonial text-center">
              <h2>SOME OF OUR RECRUITERS</h2>
              <div
                className="owl-carousel owl-theme owl-loaded owl-drag"
                id="recuiters-slider"
              >
                <OwlCarousel
                  className="owl-theme"
                  loop={true}
                  margin={20}
                  dots={false}
                  nav={true}
                  items={2}
                  autoplay={true}
                  responsive={{
                    0: {
                      items: 2,
                      nav: false,
                      dots: true,
                    },

                    600: {
                      items: 2,
                      nav: false,
                    },
                    767: {
                      items: 3,
                      nav: true,
                    },
                    1000: {
                      items: 3,
                      nav: true,
                    },
                  }}
                >
                  <div className="item">
                    <div className="rec-logo">
                      <img src={logo8} alt="" width="155" height="165" />
                    </div>
                  </div>

                  <div className="item">
                    <div className="rec-logo">
                      <img src={logo9} alt="" width="155" height="165" />
                    </div>
                  </div>

                  <div className="item">
                    <div className="rec-logo">
                      <img src={logo10} alt="" width="155" height="165" />
                    </div>
                  </div>

                  <div className="item">
                    <div className="rec-logo">
                      <img src={logo1} alt="" width="155" height="165" />
                    </div>
                  </div>

                  <div className="item">
                    <div className="rec-logo">
                      <img src={logo2} alt="" width="155" height="165" />
                    </div>
                  </div>

                  <div className="item">
                    <div className="rec-logo">
                      <img src={logo3} alt="" width="155" height="165" />
                    </div>
                  </div>

                  <div className="item">
                    <div className="rec-logo">
                      <img src={logo4} alt="" width="155" height="165" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="rec-logo">
                      <img src={logo5} alt="" width="155" height="165" />
                    </div>
                  </div>

                  <div className="item">
                    <div className="rec-logo">
                      <img src={logo6} alt="" width="155" height="165" />
                    </div>
                  </div>

                  <div className="item">
                    <div className="rec-logo">
                      <img src={logo7} alt="" width="155" height="165" />
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruiterSection;
