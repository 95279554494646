import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import OtpFormInput from "./otpFormInput";
import {
  API_LOGIN,
  API_GET_OTP,
  API_GET_LEAD_DETAILS_BY_ID,
} from "../../../api";
import { GLOBAL_CONTEXT } from "../../../routes";
import { ERROR_MSG, FORM_PLACEHOLDER } from "../../../constants";
import AuthHandler from "../../../utility";
import "./mobileOTPStyle.css";
import ReCAPTCHA from "react-google-recaptcha";

const MobileOTP = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formMessage, setFormMessage] = useState("");
  const [loginBlock, setLoginBlock] = useState(1);
  const [phonenumber, setPhonenumber] = useState("");
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [otpEndpoint, setOtpEndpoint] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [otpGenerated, setOtpGenerated] = useState(false);
  const [otpVerfied, setOtpVerfied] = useState(false);
  const [otp, setOtp] = useState("");
  const [buttonText, setButtonText] = useState("Get OTP");
  const [resend, setResend] = useState(false);
   const [showCaptcha, setshowCaptcha] = useState(false);

  const history = useHistory();
  const GLOBAL_DATA = useContext(GLOBAL_CONTEXT);
  const { setGlobalContext } = GLOBAL_DATA;

 const setcaptchaTokenAndGetOTP = (captchaToken) => {
  onGenerateOtp(phonenumber, "Phone", captchaToken);
      };

  const onVerifyOtp = () => {
    setIsLoading(true);
    API_LOGIN({
      body: {
        OTPSigninRequest: true,
        OTPSigninData: {
          Otp: otp,
          FieldContent: phonenumber,
          Code: otpCode,
        },
      },
    })
      .then(function (response) {
        const { data } = response;
        const { message } = data;
        const { Status, ExceptionMessage, LeadId, AuthKey } = message;

        if (Status === "Error") {
          setFormMessage(ExceptionMessage);
          setTimeout(() => {
            setFormMessage("");
          }, 6000)
          setIsLoading(false);
        } else {
          const authSessionInfo = {
            isAuthenticated: true,
            leadId: LeadId,
            authKey: AuthKey,
          };

          AuthHandler.SetAuthSessionInfoCookie(authSessionInfo, setGlobalContext);
          const queryParams = window.location.search ?? "";
          // history.push("/dashboard");
          window.location.href = `/dashboard/${queryParams}`;
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        setOtpError(error.data.ExceptionMessage);
        setFormMessage(error.data.ExceptionMessage);
      });
  };

  const mobileNumberHandler = (telNumber, selectedCountry) => {
    let mobilecNumber = telNumber.replace(selectedCountry.dialCode, "");
    let mobile = mobilecNumber ? mobilecNumber.replace(/[^0-9]/g, "") : null;
    let mobileTel = "+" + selectedCountry.dialCode + "-" + mobile;
    setPhonenumber(mobileTel);
    setshowCaptcha(false);
    setOtpGenerated(false)
    setOtpCode("");
    setOtpEndpoint("");
    setOtpError("");
    setButtonText("Get OTP");
    setOtpVerfied(false);
    setResend(false);
    setshowCaptcha(false);
    setOtp("");
    if (
      selectedCountry.name === "India (भारत)" ||
      selectedCountry.name === "Canada" ||
      selectedCountry.name === "United States" ||
      selectedCountry.name === "Nepal (नेपाल)"
    ) {
      setFormMessage("");
    } else {
      setFormMessage("Login not possible for this region");
    }
  };

  const onGenerateOtp = (value, type, captchaToken) => {
    setVerifyLoading(true);
    if (value) {
      API_GET_OTP({
        body: {
          FieldSchemaName: type,
          FieldContent: value,
           IsSendOTPViaLapp: false,
        OTPField: {
        SchemaName: type,
           },
        Response: captchaToken,
        SendOTPLapp: "",
        SendOTPLappEnv: 0,
        },
      })
        .then((res) => {
          const { message } = res?.data;
          const { Code, Status, ExceptionMessage } = message;
          setshowCaptcha(false);
          if (Status === "Success") {
            setButtonText("OTP Sent");
            setOtpCode(Code);
            setResend(true);
            setOtpEndpoint(value);
            setOtpGenerated(true);
            setVerifyLoading(false);
          } else if (Status === "Error") {
            setFormMessage(ExceptionMessage);
            setOtpGenerated(false);
            setVerifyLoading(false);
            setButtonText("Get OTP");
          } else {
            setVerifyLoading(false);
            setButtonText("Get OTP");
            setFormMessage(ERROR_MSG.SERVER_ERROR);
          }
        })
        .catch(function (error) {
          setshowCaptcha(false);
          setFormMessage(ERROR_MSG.SERVER_ERROR);
          setOtpGenerated(false);
          setVerifyLoading(false);
          setButtonText("Get OTP");
        });
    } else {
      setshowCaptcha(false);
      setPhoneNumberError(ERROR_MSG.REQUIRED);
    }
  };

  const onhandleSubmit = () => {
    setIsLoading(true);
    API_LOGIN({ EmailAddress: email, Password: password })
      .then(function (response) {
        API_GET_LEAD_DETAILS_BY_ID({
          leadId: response.data.LeadId,
        })
          .then(function (res) {
            const completeLeadDetails = res.data[0];

            const authSessionInfo = {
              isAuthenticated: true,
              leadId: response.data.LeadId,
              authKey: response.data.AuthKey,
            };

            if (completeLeadDetails) {
              const leadDetails = JSON.stringify({
                email: completeLeadDetails.EmailAddress,
                firstName: completeLeadDetails.FirstName,
                lastName: completeLeadDetails.LastName,
              });
              sessionStorage.setItem("user-info", leadDetails);
            }

            AuthHandler.SetAuthSessionInfoCookie(authSessionInfo);
            props.updateLoginInfo(authSessionInfo);
            props.history.push("/Home");
            setIsLoading(false);
          })
          .catch(function (error) {
            console.log(error, "error");
          });
      })
      .catch(function (error) {
        setIsLoading(false);
        setFormMessage(error.response.data.ExceptionMessage);
      });
  };

  // Login via Mobile or Email Choice
  const LoginChoice = () => {
    return (
      <div className="login-form-choice">
        <input
          type="radio"
          id="phone-radio"
          name="select-form"
          value="phone"
          checked={loginBlock === 1}
          onClick={() => setLoginBlock(1)}
        />
        <label for="phone-radio">Login with Phone Number</label>
        <input
          type="radio"
          id="email-radio"
          name="select-form"
          value="email"
          checked={loginBlock === 2}
          onClick={() => setLoginBlock(2)}
        />
        <label for="phone-email">Login with Email</label>
      </div>
    );
  };

  return (
    <>
      <div className="form-skeleton loginFormWrapper">

        <div className="formHeadingWrapper">
          {/* <div>EXISTING USER?</div> */}
          <div>Start your Application Form</div>
        </div>

        {formMessage ? <p className="form-message"> {formMessage}</p> : <div className="py-3"></div>}

        {loginBlock === 1 ? (
          <div className="login-phone">
            <form
              action=""
              method="post"
              className="form-wrapper-box"
              onSubmit={(e) => {
                e.preventDefault();
                onVerifyOtp();
              }}
            >
              <Input
                type="login-otp"
                handleChange={(value, selectedCountry) => {
                  mobileNumberHandler(value, selectedCountry);
                }}
                handleBlur={() => { }}
                value={phonenumber}
                name="Phone"
                error={phoneNumberError}
                label="Mobile"
                mandatory={true}
                placeholder={FORM_PLACEHOLDER.EMAIL}
                verified={otpVerfied}
                onGenerateOtp={() => setshowCaptcha(true)}
                status={verifyLoading}
                buttonText={buttonText}
                resend={resend}
              />

              {otpGenerated ? (
                <OtpFormInput
                  otpHandler={{}}
                  onResendOtp={() => setshowCaptcha(true)}
                  setResend={() => {
                    setshowCaptcha(false);
                    setButtonText("Resend OTP");
                    setResend(!resend);
                  }}
                  setOtp={setOtp}
                  otp={otp}
                  status={verifyLoading}
                  type="Phone"
                  resend={resend}
                />
              ) : null}
              <div className="form-group">
                <Button
                  disabled={otp.length === 6 ? !resend ? true :false : true}
                  type="submit"
                  className="btn-primary submit-button"
                  text="Sign in"
                  status={isLoading}
                />
                {showCaptcha && (
              <div>
              <ReCAPTCHA
              sitekey="6LdIUEckAAAAAAUivlJU7u16W-tOXRnl1gayXLLh"
              onChange={(token) => setcaptchaTokenAndGetOTP(token)}
              />
              </div>
              )}
              </div>
            </form>
          </div>
        ) : (
          <div className="login-email">
            <form
              action=""
              method="post"
              className="form-wrapper-box"
              onSubmit={(e) => {
                e.preventDefault();
                onhandleSubmit();
              }}
            >
              <Input
                type="email"
                handleChange={(value) => {
                  setEmail(value);
                }}
                handleBlur={() => { }}
                value={email}
                name="Email"
                error={false}
                label="Email"
                mandatory={true}
                placeholder={FORM_PLACEHOLDER.EMAIL}
              />

              <Input
                type="password"
                handleChange={(value) => {
                  setPassword(value);
                }}
                handleBlur={() => { }}
                value={password}
                name="Password"
                error={false}
                label="Password"
                mandatory={true}
                placeholder={FORM_PLACEHOLDER.PASSWORD}
                sideLink={
                  <div className="forgot-password">
                    <a
                      href="javascript:void(0)"
                      onClick={() => props.changeWidgetState("FORGOTPASSWORD")}
                    >
                      Forgot Password?
                    </a>
                  </div>
                }
              />
              <div className="form-group">
                <Button
                  disabled={!email || !password}
                  type="submit"
                  className="btn-primary submit-button"
                  text="Login"
                  status={isLoading}
                />
              </div>
            </form>
          </div>
        )}
      </div>
      {/* <div className="mt-3 tranform-lg-login">
        <PoweredByLeadSquared />
      </div> */}
    </>
  );
};

export default MobileOTP;
