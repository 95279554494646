import location from "../../../images/location-icon.png"
import phone from "../../../images/phone-icon.png"
import mail from "../../../images/mail-icon.png"
import whatsapp from "../../../images/whatsapp-icon.png"
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";

const Footer = () => {
  return (
    <>
     <section className="devsecall devsix">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="imgtxtb">
          <img src={location} alt="" />
          <a href="https://goo.gl/maps/3mRgxHvXR1U7DpVk8" target="_blank">
            Dev Bhoomi Campus,
            <br />
            Manduwala,Dehradun- 248007, Uttarakhand
          </a>
        </div>
        <div className="imgtxtb">
          <img src={phone} alt="" />
          <a href="tel:0135269424145 ">0135-2694241-45 </a>
        </div>
        <div className="imgtxtb">
          <img src={phone} alt="" />
          <a href="tel:18001034049">1800 103 4049 </a>
        </div>
        <div className="imgtxtb">
          <img src={whatsapp} alt="" />
          <a href="https://wa.me/+0919411519803">9411519803</a>
        </div>
        <div className="imgtxtb">
          <img src={mail} alt="" />
          <a href="mailto:info@dbuu.ac.in">info@dbuu.ac.in </a>
        </div>
      </div>
    </div>
    <div className="row copyfooter">
    <p className="copyright"> “Copyright © 2023 - All Rights Reserved - Official Admission Portal of Dev Bhoomi Uttarakhand University Dehradun, Uttarakhand, India.” </p>
    </div>
  </div>
</section>

    </>
  );
};

export default Footer;
