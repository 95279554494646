import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import { ACCOUNT_DETAILS } from "../../../config";
import { LoadingScreen } from "../../../components/Loader";
import "../../../css/platformFormLoader.css";
import "../../../css/platformFormStyles.css";
import "./style.css";
/* global lsq_setupForm */
/* global lsq_setupPortalProcess */

const AdmissionForm = () => {
  const [loader, setLoader] = useState(true);

  const history = useHistory();

  const { formsData } = ACCOUNT_DETAILS;
  const {
    lsqApiServiceUrl,
    lsqFormUrl,
    lsqAppUrl,
    applicationform,
    lsqFormThemeColor,
    lsqAuthKey,
  } = formsData;
  const { gradAdmissionProcessId } = applicationform;

  let processId = gradAdmissionProcessId;

  useEffect(() => {
    // Fetching LeadId & AcitivtyId from URL
    const queryParams = new URLSearchParams(window.location.search);
    const leadId = queryParams.get("leadId");
    const activityId = queryParams.get("activityId");

    try {
      $.getScript(lsqFormUrl)
        .done(function (data) {
          lsq_setupPortalProcess({
            id: processId,
            authKeyProvider: () => lsqAuthKey,
            leadId: leadId ?? "",
            activityId: activityId ?? "",
            templateName: "Number",
            themeOptions: {
              primaryButtonColor: lsqFormThemeColor,
            },
          });
          setLoader(false);
        })
        .fail(function (error) {
          const queryParams = window.location.search ?? "";
          window.location.href = `/${queryParams}`;
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const loadOverrideCSS = () => {
    setLoader(false);
  };

  const onLSQFormSubmissionSuccess = () => {
    setTimeout(() => {
      const queryParams = window.location.search ?? "";
      window.location.href = `/dashboard/${queryParams}`;
    }, 1500);
  };

  const onLSQFormLoadError = (e) => {
    console.log(e);
    const queryParams = window.location.search ?? "";
    window.location.href = `/${queryParams}`;
  };

  const onLSQFormSaveFailure = (e) => {
    console.log(e);
    // window.location.href = "/";
  };

  const onLSQFormSubmissionSuccessAtEachStep = (e) => {
    console.log(">>> Save at Each Step<<<");

    const queryParams = new URLSearchParams(window.location.search);
    const leadId = queryParams.get("leadId");
    const activityIdFromUrl = queryParams.get("activityId");

    const activityIdFromEvent =
      e.detail.data.ActivityDetails.length !== 0
        ? e.detail.data.ActivityDetails[0].ActivityId
        : "";

    if (!activityIdFromUrl) {
      history.push(
        `/admissionForm/?leadId=${leadId}&activityId=${activityIdFromEvent}`
      );
    }
  };

  if (window.lsqFormContainer) {
    window.lsqFormContainer.addEventListener(
      "lsqformloadcomplete",
      loadOverrideCSS
    );
    window.lsqFormContainer.addEventListener(
      "lsqformsubmissionsuccessateachstep",
      onLSQFormSubmissionSuccessAtEachStep
    );
    window.lsqFormContainer.addEventListener(
      "lsqformsubmissionsuccess",
      onLSQFormSubmissionSuccess
    );
    window.lsqFormContainer.addEventListener(
      "LsqFormSaveFailure",
      onLSQFormSaveFailure
    );
    window.lsqFormContainer.addEventListener(
      "LSQFormLoadError",
      onLSQFormLoadError
    );
  }

  return (
    <>
      {loader && <LoadingScreen />}
      <div className={"formParentWrapper applicationFormWrapper"}>
        <div
          id="lsq-form-modal"
          data-process-id={processId}
          ref={(elem) => (window.lsqFormContainer = elem)}
          className="modal-v4 fullscreen external lsq-external-form-container"
        >
          <div class="lsq-external-shimmer-container">
            <div class="shimmer-header">
              <div class="shimmer-center">
                <lines class="shimmer-shine width50"></lines>
              </div>
              <lines class="shimmer-shine"></lines>
            </div>
            <div class="shimmer-body">
              <div class="shimmer-field">
                <shimmerinput class="shimmer-shine"></shimmerinput>
                <shimmerlabel class="shimmer-shine"></shimmerlabel>
              </div>
              <div class="shimmer-field">
                <shimmerinput class="shimmer-shine"></shimmerinput>
                <shimmerlabel class="shimmer-shine"></shimmerlabel>
              </div>
              <div class="shimmer-field">
                <shimmerinput class="shimmer-shine"></shimmerinput>
                <shimmerlabel class="shimmer-shine"></shimmerlabel>
              </div>
              <div class="shimmer-field">
                <shimmerinput class="shimmer-shine"></shimmerinput>
                <shimmerlabel class="shimmer-shine"></shimmerlabel>
              </div>
              <div class="shimmer-field">
                <shimmerinput class="shimmer-shine"></shimmerinput>
                <shimmerlabel class="shimmer-shine"></shimmerlabel>
              </div>
            </div>
          </div>

          <div className="lsq-form-container-wrapper"></div>

          <div class="lsq-form-hidden-fields">
            <input
              id="lsq-api-service-url"
              name="lsq-api-service-url"
              type="hidden"
              value={lsqApiServiceUrl}
            />

            <input
              id="lsq-app-url"
              name="lsq-app-url"
              type="hidden"
              value={lsqAppUrl}
            />

            <input
              id="lsq-process-id-hidden"
              name="lsq-process-id-hidden"
              type="hidden"
              value={processId}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdmissionForm;
