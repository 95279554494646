import { IoMdDownload, IoIosArrowForward } from "react-icons/io";
import showNotification from "../../utility/notify";

const downloadFile = (url) => {
  url
    ? window.open(url)
    : showNotification(
        "We are unable to download your file. Please refresh and try again", // Message
        "File Download Error", // Title
        "error"
      ); // Type
};

const STATUS_MAPPING = (status, activityId, leadId, activityFields) => {
  const mappingObject = {
    "Application Pending": {
      label: "Draft",
      themeColor: "orange",
      actionLinks: {
        "Continue Application": {
          icon: "",
          clickHandler: () => {
            window.location.href = `/applicationForm/?activityId=${activityId}&&leadId=${leadId}`;
          },
        },
      },
    },
    "Registration Done": {
      label: "Registered",
      themeColor: "blue",
      actionLinks: {
        "Continue Application": {
          icon: "",
          clickHandler: () => {
            window.location.href = `/applicationForm/?activityId=${activityId}&&leadId=${leadId}`;
          },
        },
        "Application Fee Receipt": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_41 } = activityFields;
            downloadFile(mx_Custom_41);
          },
        },
      },
    },
    "Registration Form Submitted": {
      label: "Applied",
      themeColor: "blue",
      actionLinks: {
        "Application Fee Receipt": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_41 } = activityFields;
            downloadFile(mx_Custom_41);
          },
        },
        "Download Application PDF": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_14 } = activityFields;
            downloadFile(mx_Custom_14);
          },
        },
      },
    },
    Submitted: {
      label: "Applied",
      themeColor: "blue",
      actionLinks: {
        "Application Fee Receipt": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_41 } = activityFields;
            downloadFile(mx_Custom_41);
          },
        },
        "Download Application PDF": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_14 } = activityFields;
            downloadFile(mx_Custom_14);
          },
        },
      },
    },
    Verified: {
      label: "Approved",
      themeColor: "yellow",
      actionLinks: {
        "Pay Course Fee": {
          icon: "",
          clickHandler: () => {
            window.location.href = `/courseFeeForm/?activityId=${activityId}&&leadId=${leadId}`;
          },
        },
        "Application Fee Receipt": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_41 } = activityFields;
            downloadFile(mx_Custom_41);
          },
        },
        "Download Application PDF": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_14 } = activityFields;
            downloadFile(mx_Custom_14);
          },
        },
      },
    },
    "Course Fee Paid": {
      label: "Course Fee Paid",
      themeColor: "green",
      actionLinks: {
        "Application Fee Receipt": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_41 } = activityFields;
            downloadFile(mx_Custom_41);
          },
        },
        "Download Application PDF": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_14 } = activityFields;
            downloadFile(mx_Custom_14);
          },
        },
        "Course Fee Receipt": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_42 } = activityFields;
            downloadFile(mx_Custom_42);
          },
        },
        "Provisional Admission form": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_43 } = activityFields;
            downloadFile(mx_Custom_43);
          },
        },
      },
    },
    Rejected: {
      label: "Rejected",
      themeColor: "red",
      actionLinks: {},
    },
  };
  if (status) return mappingObject[status];
  else
    return {
      label: "Unknown",
      themeColor: "red",
      actionLinks: {},
    };
};

export const STATUS_MAPPING_PHD = (
  status,
  activityId,
  leadId,
  activityFields
) => {
  const mappingObject = {
    "Application Pending": {
      label: "Draft",
      themeColor: "orange",
      actionLinks: {
        "Continue Application": {
          icon: "",
          clickHandler: () => {
            window.location.href = `/applicationForm/?activityId=${activityId}&&leadId=${leadId}`;
          },
        },
      },
    },
    "Registration Done": {
      label: "Registered",
      themeColor: "blue",
      actionLinks: {
        "Continue Application": {
          icon: "",
          clickHandler: () => {
            window.location.href = `/applicationForm/?activityId=${activityId}&&leadId=${leadId}`;
          },
        },
        "Application Fee Receipt": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_41 } = activityFields;
            downloadFile(mx_Custom_41);
          },
        },
      },
    },
    Submitted: {
      label: "Applied",
      themeColor: "blue",
      actionLinks: {
        "Application Fee Receipt": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_41 } = activityFields;
            downloadFile(mx_Custom_41);
          },
        },
        "Download Application PDF": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_14 } = activityFields;
            downloadFile(mx_Custom_14);
          },
        },
        "Download Admit Card": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_22 } = activityFields;
            downloadFile(mx_Custom_22);
          },
        },
      },
    },
    "Registration Form Submitted": {
      label: "Applied",
      themeColor: "blue",
      actionLinks: {
        "Application Fee Receipt": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_41 } = activityFields;
            downloadFile(mx_Custom_41);
          },
        },
        "Download Application PDF": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_14 } = activityFields;
            downloadFile(mx_Custom_14);
          },
        },
        "Download Admit Card": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_22 } = activityFields;
            downloadFile(mx_Custom_22);
          },
        },
      },
    },
    Verified: {
      label: "Approved",
      themeColor: "yellow",
      actionLinks: {
        "Pay Course Fee": {
          icon: "",
          clickHandler: () => {
            window.location.href = `/courseFeeForm/?activityId=${activityId}&&leadId=${leadId}`;
          },
        },
        "Application Fee Receipt": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_41 } = activityFields;
            downloadFile(mx_Custom_41);
          },
        },
        "Download Application PDF": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_14 } = activityFields;
            downloadFile(mx_Custom_14);
          },
        },
        "Download Admit Card": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_22 } = activityFields;
            downloadFile(mx_Custom_22);
          },
        },
      },
    },
    "Course Fee Paid": {
      label: "Course Fee Paid",
      themeColor: "green",
      actionLinks: {
        "Application Fee Receipt": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_41 } = activityFields;
            downloadFile(mx_Custom_41);
          },
        },
        "Download Application PDF": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_14 } = activityFields;
            downloadFile(mx_Custom_14);
          },
        },
        "Course Fee Receipt": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_42 } = activityFields;
            downloadFile(mx_Custom_42);
          },
        },
        "Provisional Admission form": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_43 } = activityFields;
            downloadFile(mx_Custom_43);
          },
        },
        "Download Admit Card": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_22 } = activityFields;
            downloadFile(mx_Custom_22);
          },
        },
      },
    },
    Rejected: {
      label: "Rejected",
      themeColor: "red",
      actionLinks: {},
    },
  };
  if (status) return mappingObject[status];
  else
    return {
      label: "Unknown",
      themeColor: "red",
      actionLinks: {},
    };
};

export const STATUS_MAPPING_UG_PG = (
  status,
  activityId,
  leadId,
  activityFields
) => {
  const mappingObject = {
    "Application Pending": {
      label: "Draft",
      themeColor: "orange",
      actionLinks: {
        "Continue Application": {
          icon: "",
          clickHandler: () => {
            window.location.href = `/applicationForm/?activityId=${activityId}&&leadId=${leadId}`;
          },
        },
      },
    },
    "Registration Done": {
      label: "Registered",
      themeColor: "blue",
      actionLinks: {
        "Continue Application": {
          icon: "",
          clickHandler: () => {
            window.location.href = `/applicationForm/?activityId=${activityId}&&leadId=${leadId}`;
          },
        },
        "Application Fee Receipt": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_41 } = activityFields;
            downloadFile(mx_Custom_41);
          },
        },
      },
    },
    Submitted: {
      label: "Applied",
      themeColor: "blue",
      actionLinks: {
        "Application Fee Receipt": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_41 } = activityFields;
            downloadFile(mx_Custom_41);
          },
        },
        "Download Application PDF": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_27 } = activityFields;
            downloadFile(mx_Custom_27);
          },
        },
        "Continue to Admission Form": {
          icon: <IoIosArrowForward />,
          isButton: true,
          clickHandler: () => {
            window.location.href = `/admissionFrom/?activityId=${activityId}&&leadId=${leadId}`;
          },
        },
      },
    },
    "Registration Form Submitted": {
      label: "Applied",
      themeColor: "blue",
      actionLinks: {
        "Application Fee Receipt": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_41 } = activityFields;
            downloadFile(mx_Custom_41);
          },
        },
        "Download Application PDF": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_27 } = activityFields;
            downloadFile(mx_Custom_27);
          },
        },
        "Continue to Admission Form": {
          icon: <IoIosArrowForward />,
          isButton: true,
          clickHandler: () => {
            window.location.href = `/admissionFrom/?activityId=${activityId}&&leadId=${leadId}`;
          },
        },
      },
    },
    Verified: {
      label: "Approved",
      themeColor: "yellow",
      actionLinks: {
        "Pay Course Fee": {
          icon: "",
          clickHandler: () => {
            window.location.href = `/courseFeeForm/?activityId=${activityId}&&leadId=${leadId}`;
          },
        },
        "Application Fee Receipt": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_41 } = activityFields;
            downloadFile(mx_Custom_41);
          },
        },
        "Download Application PDF": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_27 } = activityFields;
            downloadFile(mx_Custom_27);
          },
        },
      },
    },
    "Course Fee Paid": {
      label: "Course Fee Paid",
      themeColor: "green",
      actionLinks: {
        "Application Fee Receipt": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_41 } = activityFields;
            downloadFile(mx_Custom_41);
          },
        },
        "Download Application PDF": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_27 } = activityFields;
            downloadFile(mx_Custom_27);
          },
        },
        "Course Fee Receipt": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_42 } = activityFields;
            downloadFile(mx_Custom_42);
          },
        },
        "Provisional Admission form": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_43 } = activityFields;
            downloadFile(mx_Custom_43);
          },
        },
      },
    },
    Rejected: {
      label: "Rejected",
      themeColor: "red",
      actionLinks: {},
    },
    "Admission Form Submitted": {
      label: "Draft- Admission Form",
      themeColor: "orange",
      actionLinks: {
        "Application Fee Receipt": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_41 } = activityFields;
            downloadFile(mx_Custom_41);
          },
        },
        "Download Application PDF": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_27 } = activityFields;
            downloadFile(mx_Custom_27);
          },
        },
        "Continue to Admission Form": {
          icon: <IoIosArrowForward />,
          isButton: true,
          clickHandler: () => {
            window.location.href = `/admissionFrom/?activityId=${activityId}&&leadId=${leadId}`;
          },
        },
      },
    },
    "Admission Done": {
      label: "Admission Done",
      themeColor: "green",
      actionLinks: {
        "Application Fee Receipt": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_41 } = activityFields;
            console.log(mx_Custom_41);
            downloadFile(mx_Custom_41);
          },
        },
        "Download Application PDF": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_27 } = activityFields;
            console.log(mx_Custom_27);
            downloadFile(mx_Custom_27);
          },
        },
        "Admission Fee Receipt": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_28 } = activityFields;
            const mx_Custom_28_Obj = JSON.parse(mx_Custom_28);
            console.log(mx_Custom_28_Obj.mx_CustomObject_5);
            downloadFile(mx_Custom_28_Obj.mx_CustomObject_5);
          },
        },
        "Download Admission PDF": {
          icon: <IoMdDownload />,
          clickHandler: () => {
            const { mx_Custom_28 } = activityFields;
            const mx_Custom_28_Obj = JSON.parse(mx_Custom_28);
            console.log(mx_Custom_28_Obj.mx_CustomObject_7);
            downloadFile(mx_Custom_28_Obj.mx_CustomObject_7);
          },
        },
      },
    },
  };
  if (status) return mappingObject[status];
  else
    return {
      label: "Unknown",
      themeColor: "red",
      actionLinks: {},
    };
};

export default STATUS_MAPPING;
