import { useContext, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { GLOBAL_CONTEXT } from ".";
import { ACCOUNT_DETAILS } from "../config";
import { API_GET_LEAD_DETAILS_BY_ID } from "../api";
import AuthHandler from "../utility";
import Dashboard from "../views/Dashboard";
import ContactUs from "../components/ContactUs";
import ApplicationForm from "../forms/PostLogin/ApplicationForm";
import CourseFeeForm from "../forms/PostLogin/CourseFeeForm";
import ProfileForm from "../forms/PostLogin/ProfileForm";
import { HeaderAfterLogin } from "../components/Header";
import { FooterAfterLogin } from "../components/Footer";
import { LoadingScreen } from "../components/Loader";
import AdmissionForm from "../forms/PostLogin/AdmissionFrom";

const PostLoginRoutes = () => {
  const leadId = AuthHandler.GetLeadId();
  const GLOBAL_DATA = useContext(GLOBAL_CONTEXT);
  const { setGlobalContext } = GLOBAL_DATA;
  const [isLoading, setIsLoading] = useState(true);
  const [fallbackUI, setFallbackUI] = useState(false);

  // Fetching Lead Data for POST LOGIN COMPONENTS
  const getLeadDetails = async () => {
    try {
      const response = await API_GET_LEAD_DETAILS_BY_ID({
        params: { id: leadId },
      });
      const { data } = response;
      const { message, errorMessage } = data;
      const leadDetails = message?.[0];

      if (errorMessage) {
        setFallbackUI(true);
        return;
      }

      // Below Fields will be SET in Session Storage
      const leadFields = [
        "ProspectID",
        "FirstName",
        "LastName",
        "EmailAddress",
        "Phone",
        "Mobile",
        "mx_Program_Level",
      ];

      leadFields.forEach((field) => {
        sessionStorage.setItem(field, leadDetails[field] ?? "");
      });

      setGlobalContext((globalContext) => ({
        ...globalContext,
        leadData: leadDetails,
      }));

      setIsLoading(false);
    } catch (error) {
      setFallbackUI(true);
    }
  };

  useEffect(() => {
    getLeadDetails();
  }, []);

  if (fallbackUI) {
    throw new Error("API Call Failed");
  }

  if (isLoading) {
    return <LoadingScreen />;
  } else {
    return (
      <>
        <HeaderAfterLogin />

        <Switch>
          <Route exact path={["/", "/dashboard"]}>
            <Dashboard />
          </Route>

          <Route exact path="/contactUs" component={ContactUs} />

          <Route path="/applicationForm" component={ApplicationForm} />

          <Route path="/courseFeeForm" component={CourseFeeForm} />

          <Route path="/profileForm" component={ProfileForm} />

          <Route path="/admissionFrom" component={AdmissionForm} />

          <Route component={Dashboard} />
        </Switch>
        <FooterAfterLogin />
      </>
    );
  }
};

export default PostLoginRoutes;
