import React from "react";
import { Route, Switch } from "react-router-dom";
import LandingPage from "../views/LandingPage";
import { FooterBeforeLogin } from "../components/Footer";

const PreLoginRoutes = () => {
  return (
    <>
      <Switch>        
        <Route exact path={["/", "/home"]}>
          <LandingPage />
        </Route>

        <Route component={LandingPage} />
      </Switch>
      <FooterBeforeLogin />
    </>
  );
};

export default PreLoginRoutes;
