import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { API_FORGOT_PASSWORD } from "../../../api/index";
import { FORM_PLACEHOLDER } from "../../../constants";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import "./style.css";

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState("");
  const [apiStatus,setApiStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formMessage, setFormMessage] = useState("");
  const history = useHistory();

  const onhandleSubmit = () => {
    setIsLoading(true);
    const payload = {body: { "EmailAddress": email }}
    API_FORGOT_PASSWORD(payload)
      .then(function (response) {
          const { Status,ExceptionMessage } = response?.data?.message;
          if(Status === 'Error'){
            setIsLoading(false);
            setFormMessage(ExceptionMessage);
          }else{
            setIsLoading(false);
            setApiStatus(true);
            setFormMessage("Reset link sent successfully");
          }
       
      })
      .catch(function (error) {
        setIsLoading(false);
        setFormMessage("forgot password wrapper API Failed");
      });
  };

  return (
      <div className="form-skeleton" style={{paddingTop: "80px"}}>
        <div className="forgot-form-headings">
          <p className="forgot-title">Forgot Password</p>
          <p className="forgot-caption">Please enter your email, We will send a password reset link.</p>
        </div>

        {formMessage && <p className={`${apiStatus ? 'form-success-message':'form-fail-message'}`}> {formMessage}</p>}
        
        <form
          action=""
          method="post"
          className="form-wrapper-box"
          onSubmit={(e) => {
            e.preventDefault();
            onhandleSubmit();
          }}
        >
          <Input
            type="email"
            handleChange={setEmail}
            value={email}
            name="Email"
            error={false}
            label="Email"
            mandatory={true}
            placeholder={FORM_PLACEHOLDER.EMAIL}
          />

          <div className="form-group">
            <Button
              disabled={!email || isLoading}
              type="submit"
              className="btn-primary submit-button"
              text="Send Password Reset Link"
              status={isLoading}
            />
          </div>
          <div className="back-to-login">
            <Button
                disabled={false}
                type="button"
                className="backToLoginBtn"
                text="Back To Login"
                status={false}
                handleClick ={() =>history.push('/')}
              />
          </div>
        </form>
      </div>
  );
};

export default ForgotPasswordForm;
