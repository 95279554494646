import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import OtpFormInput from "./otpFormInput";
import {
  API_LOGIN,
  API_GET_OTP,
  API_GET_LEAD_DETAILS_BY_ID,
} from "../../../api";
import { GLOBAL_CONTEXT } from "../../../routes";
import { ERROR_MSG, FORM_PLACEHOLDER } from "../../../constants";
import AuthHandler from "../../../utility";
import "./mobileOTPStyle.css";
import ReCAPTCHA from "react-google-recaptcha";

const EmailOTP = (props) => {
 
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formMessage, setFormMessage] = useState("");
  const [loginBlock, setLoginBlock] = useState(1);
  const [phonenumber, setPhonenumber] = useState("");
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [phoNumberError, setPhoneNumberError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [otpEndpoint, setOtpEndpoint] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [otpGenerated, setOtpGenerated] = useState(false);
  const [otpVerfied, setOtpVerfied] = useState(false);
  const [otp, setOtp] = useState("");
  const [buttonText, setButtonText] = useState("Get OTP");
  const [resend, setResend] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [showCaptcha, setshowCaptcha] = useState(false);

  const history = useHistory();
  const GLOBAL_DATA = useContext(GLOBAL_CONTEXT);
  const { setGlobalContext } = GLOBAL_DATA;

  const handleEmailChange = (value) => {
    setEmail(value);
    setshowCaptcha(false);
    setOtpGenerated(false)
    setOtpCode("");
    setOtpEndpoint("");
    setOtpError("");
    setButtonText("Get OTP");
    setOtpVerfied(false);
    setResend(false);
    setshowCaptcha(false);
    setFormMessage("")
    setOtp("");
  }

  const setcaptchaTokenAndGetOTP = (captchaToken) => {
    onGenerateOtp(email, "EmailAddress", captchaToken);
      };
  const onVerifyOtp = () => {
    setIsLoading(true);
    API_LOGIN({
      body: {
        OTPSigninRequest: true,
        OTPSigninData: {
          Otp: otp,
          FieldContent: email,
          Code: otpCode,
        },
      },
    })
      .then(function (response) {
        const { data } = response;
        const { message } = data;
        const { Status, ExceptionMessage, LeadId, AuthKey } = message;

        if (Status === "Error") {
          setFormMessage(ExceptionMessage);
          setTimeout(() => {
            setFormMessage("");
          }, 6000)
          setIsLoading(false);
        } else {
          const authSessionInfo = {
            isAuthenticated: true,
            leadId: LeadId,
            authKey: AuthKey,
          };

          AuthHandler.SetAuthSessionInfoCookie(authSessionInfo, setGlobalContext);
          const queryParams = window.location.search ?? "";
          // history.push("/dashboard");
          window.location.href = `/dashboard/${queryParams}`;
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        setOtpError(error.data.ExceptionMessage);
        setFormMessage(error.data.ExceptionMessage);
      });
  };

  const onGenerateOtp = (value, type, captchaToken) => {
    setVerifyLoading(true);
    if (value) {
      API_GET_OTP({
        body: {
          FieldSchemaName: type,
          FieldContent: value,
          IsSendOTPViaLapp: false,
          OTPField: {
          SchemaName: type,
             },
          Response: captchaToken,
          SendOTPLapp: "",
          SendOTPLappEnv: 0,
        },
      })
        .then((res) => {
          const { message } = res?.data;
          const { Code, Status, ExceptionMessage } = message;
          setshowCaptcha(false);
          if (Status === "Success") {
            setButtonText("OTP Sent");
            setOtpCode(Code);
            setResend(true);
            setOtpEndpoint(value);
            setOtpGenerated(true);
            setVerifyLoading(false);
          } else if (Status === "Error") {
            setFormMessage(ExceptionMessage);
            setOtpGenerated(false);
            setVerifyLoading(false);
            setButtonText("Get OTP");
          } else {
            setVerifyLoading(false);
            setButtonText("Get OTP");
            setFormMessage(ERROR_MSG.SERVER_ERROR);
          }
        })
        .catch(function (error) {
          setshowCaptcha(false);
          setFormMessage(ERROR_MSG.SERVER_ERROR);
          setOtpGenerated(false);
          setVerifyLoading(false);
          setButtonText("Get OTP");
        });
    } else {
      setshowCaptcha(false);
      setPhoneNumberError(ERROR_MSG.REQUIRED);
    }
  };

 

 

  return (
    <>
   
      <div className="form-skeleton loginFormWrapper">

        <div className="formHeadingWrapper">
          {/* <div>EXISTING USER?</div> */}
          <div>START YOUR APPLICATION FORM</div>
        </div>

        {formMessage ? <p className="form-message"> {formMessage}</p> : <div className="py-3"></div>}
        <div className="login-phone">
            <form
              action=""
              method="post"
              className="form-wrapper-box"
              onSubmit={(e) => {
                e.preventDefault();
                onVerifyOtp();
              }}
            >
              <Input
              className="emailField"
                type="email-verify"
                handleBlur={() => { }}
                value={email}
                name="Email"
                error={emailError}
                label="Email"
                mandatory={true}
                placeholder={FORM_PLACEHOLDER.EMAIL}
                verified={otpVerfied}
                onGenerateOtp={() => setshowCaptcha(true)}
                status={verifyLoading}
                buttonText={buttonText}
                resend={resend}
                handleChange={(e) => handleEmailChange(e)}
              />
              {otpGenerated ? (
                <OtpFormInput
                  otpHandler={{}}
                  onResendOtp={() => setshowCaptcha(true)}
                  setResend={() => {
                    setshowCaptcha(false);
                    setButtonText("Resend OTP");
                    setResend(!resend);
                  }}
                  setOtp={setOtp}
                  otp={otp}
                  status={verifyLoading}
                  type="Phone"
                  resend={resend}
                />
              ) : null}

              <div className="form-group">
                <Button
                  disabled={otp.length === 6 ? !resend ? true :false : true}
                  type="submit"
                  className="btn-primary submit-button"
                  text="Sign in"
                  status={isLoading}
                />
                {showCaptcha && (
              <div>
              <ReCAPTCHA
              sitekey="6LdIUEckAAAAAAUivlJU7u16W-tOXRnl1gayXLLh"
              onChange={(token) => setcaptchaTokenAndGetOTP(token)}
              />
              </div>
              )}
              </div>
            </form>
          </div>
      </div>
      {/* <div className="mt-3 tranform-lg-login">
        <PoweredByLeadSquared />
      </div> */}
    </>
  );
};

export default EmailOTP;
