import { useLocation, useParams } from "react-router-dom";
import React, { Component } from "react";
import $ from "jquery";
import { ACCOUNT_DETAILS } from "../../../config";
import { LoadingScreen } from "../../../components/Loader";
import "../../../css/platformFormLoader.css";
import "./style.css";
import { API_GET_LEAD_DETAILS_BY_PHONE } from "../../../api";
import AuthHandler from "../../../utility";
/* global lsq_setupForm */

class RegistrationForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fullScreenLoader: true,
      intervalId: null,
    };
  }

  injectValuesOnFormLoad = () => {
    try {
      const injectValueInField = (query, value) => {
        window.lsqFormContainer.querySelector(query).value = value;
      };

      //Setting password field every 500ms to avoid browser autofill error
      let intervalId = setInterval(() => {
        // Injecting Password
        injectValueInField(
          '.lsq-form-password-item input[data-schemaname="mx_Form_Special_Password"]',
          "Ps@12345"
        );

        // Injecting Confirm Password
        injectValueInField(
          '.lsq-form-confirm-password-item input[data-schemaname="mx_Form_Special_Password"]',
          "Ps@12345"
        );
      }, 500);
      this.setState({ intervalId: intervalId });
    } catch (error) {
      console.log(error);
    }
  };

  loadOverrideCSS = () => {
    this.injectValuesOnFormLoad();

    // Changing the button Text
    try {
      const submitButton = window.lsqFormContainer.querySelector(
        ".lsq-form-action-btn"
      );
      if (submitButton) {
        submitButton.textContent = "Register";
      }
      this.setState({ fullScreenLoader: false });
    } catch (error) {
      console.log(error);
    }
  };

  onLSQFormSubmissionSuccess = () => {
    try {

      //Google tag manager - coverstion event
      window.gtag('event', 'conversion', {
        'send_to': 'AW-370536102/5x-ZCKjSpZcCEKbd17AB'
      });
      const globalThis = this;

      clearInterval(globalThis.state.intervalId);

      //Disabled Auto Login after Registration
      // let phoneNumber = document.querySelector('[id="7ecc95b8-2c72-407b-bdf1-e3739ecc01e0__tab1__section1__Phone__Lead__0"] + input').value
      // this.setState({ fullScreenLoader: true });
      // API_GET_LEAD_DETAILS_BY_PHONE({
      //   params: { phone: phoneNumber },
      // })
      //   .then(function (response) {
      //     const { data } = response;
      //     const { message } = data;
      //     const { Status, ProspectID } = message[0] ?? message;

      //     if (Status === "Error") {
      //       this.setState({ fullScreenLoader: false });
      //       window.lsqFormContainer.querySelector(
      //         ".ms-message-txt"
      //       ).textContent = "Account Created Successfully. You can login now";
      //       setTimeout(() => {
      //         window.location.href = "/";
      //       }, 1000);
      //     } else {
      //       const authSessionInfo = {
      //         isAuthenticated: true,
      //         leadId: ProspectID,
      //         authKey: "LoggedInAfterRegistration",
      //       };

      //       AuthHandler.SetAuthSessionInfoCookie(
      //         authSessionInfo,
      //         globalThis.props.setGlobalContext
      //       );

      //       // history.push("/dashboard");
      //       // window.location.href = "/dashboard";
      //     }
      //   })
      //   .catch(function (error) {
      //     this.setState({ fullScreenLoader: false });
      //     window.lsqFormContainer.querySelector(".ms-message-txt").textContent =
      //       "Account Created Successfully. You can login now";
      //     setTimeout(() => {
      //       window.location.href = "/";
      //     }, 1000);
      //   });

      window.lsqFormContainer.querySelector(".ms-message-txt").textContent =
        "Account Created Successfully. You can login now";
      setTimeout(() => {
        window.location.href = "/";
        // const queryParams = window.location.search ?? "";
        // window.location.href = `/${queryParams}`;
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  onLSQFormLoadError = (e) => {
    console.log(e);
    // window.location.href = "/";
  };

  onLSQFormSaveFailure = (e) => {
    console.log(e);
    // window.location.href = "/";
  };

  componentDidMount() {
    const globalThis = this;
    const { formsData } = ACCOUNT_DETAILS;
    const { lsqFormUrl, registrationForm } = formsData;
    const { formId, formThemeColor } = registrationForm;

    try {
      $.getScript(lsqFormUrl)
        .done(function (data) {
          lsq_setupForm({
            id: formId,
            templateName: "Number",
            themeOptions: {
              primaryButtonColor: formThemeColor,
            },
          });
          // globalThis.setState({ fullScreenLoader: false });
        })
        .fail(function (error) {
          const queryParams = window.location.search ?? "";
          window.location.href = `/${queryParams}`;
        });
    } catch (error) {
      console.log(error);
    }

    if (window.lsqFormContainer) {
      window.lsqFormContainer.addEventListener(
        "lsqformloadcomplete",
        this.loadOverrideCSS
      );

      window.lsqFormContainer.addEventListener(
        "lsqformsubmissionsuccess",
        this.onLSQFormSubmissionSuccess
      );
      window.lsqFormContainer.addEventListener(
        "LsqFormSaveFailure",
        this.onLSQFormSaveFailure
      );
      window.lsqFormContainer.addEventListener(
        "LSQFormLoadError",
        this.onLSQFormLoadError
      );
    }
  }

  render() {
    const { formsData } = ACCOUNT_DETAILS;
    const { lsqAppUrl, lsqApiServiceUrl, lsqAuthKey, registrationForm } =
      formsData;
    const { formId } = registrationForm;

    return (
      <>
        {this.state.fullScreenLoader && <LoadingScreen />}
        <div className="registrationFormWrapper">
          <div className="formHeadingWrapper">
            <div>New User?</div>
            <div>Register to create your account</div>
          </div>
          <div
            id="lsq-form-modal"
            data-form-id={formId}
            ref={(elem) => (window.lsqFormContainer = elem)}
            className="modal-v4 fullscreen external lsq-external-form-container"
          >
            {/* <div class="lsq-external-shimmer-container">
              <div class="shimmer-header">
                <div class="shimmer-center">
                  <lines class="shimmer-shine width50"></lines>
                </div>
                <lines class="shimmer-shine"></lines>
              </div>
              <div class="shimmer-body">
                <div class="shimmer-field">
                  <shimmerinput class="shimmer-shine"></shimmerinput>
                  <shimmerlabel class="shimmer-shine"></shimmerlabel>
                </div>
                <div class="shimmer-field">
                  <shimmerinput class="shimmer-shine"></shimmerinput>
                  <shimmerlabel class="shimmer-shine"></shimmerlabel>
                </div>
                <div class="shimmer-field">
                  <shimmerinput class="shimmer-shine"></shimmerinput>
                  <shimmerlabel class="shimmer-shine"></shimmerlabel>
                </div>
                <div class="shimmer-field">
                  <shimmerinput class="shimmer-shine"></shimmerinput>
                  <shimmerlabel class="shimmer-shine"></shimmerlabel>
                </div>
                <div class="shimmer-field">
                  <shimmerinput class="shimmer-shine"></shimmerinput>
                  <shimmerlabel class="shimmer-shine"></shimmerlabel>
                </div>
              </div>
            </div> */}
            <div className="lsq-form-container-wrapper"></div>
            <div className="lsq-form-hidden-fields">
              <input
                id="lsq-authKey"
                name="lsq-authKey"
                type="hidden"
                value={lsqAuthKey}
              />
              <input
                id="lsq-api-service-url"
                name="lsq-api-service-url"
                type="hidden"
                value={lsqApiServiceUrl}
              />
              <input
                id="lsq-app-url"
                name="lsq-app-url"
                type="hidden"
                value={lsqAppUrl}
              />
              <input
                id="lsq-form-id-hidden"
                name="lsq-form-id-hidden"
                type="hidden"
                value={formId}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RegistrationForm;
