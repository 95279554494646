import img1 from "../../../images/SanjayBansal.png";
import img2 from "../../../images/Aman.jpg";
import img3 from "../../../images/PREETI-KOTHIYAL.png";
import img4 from "../../../images/rktripathi.png";
import img5 from "../../../images/ak.png";
import img6 from "../../../images/rkbajaj.png";
import img8 from "../../../images/sharma.png"
import img11 from "../../../images/shubhashish.png";
import img13 from "../../../images/op.png";
import img21 from "../../../images/anamikaSaxena.png";
import img22 from "../../../images/principle.png";
import img23 from "../../../images/Dr.-Manisha-Phougat.png";
import img24 from "../../../images/kaustubhDas.png";
import img25 from "../../../images/UDYAN-AGARWAL-(2).png"
import img26 from "../../../images/bhaskerPratapChaudhary.jpg"
import img27 from "../../../images/vinodKumar.png"

import sliderArrow from "../../../images/slider-arrow.png"
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import { useEffect } from "react";
import $ from 'jquery'

const FacultSection = () => {



  return (
    <section className="devsecall devtwo">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div class="allhead strwo">A PROACTIVE & DISTINGUISHED
              <strong>LEAGUE OF LEADERSHIP</strong></div>
            {/* <div className="col-md-3"></div> */}
            {/* <div className="col-md-9 text-center carousel-section my-5"> */}
            <div className="owl-carousel owl-theme" id="faculty-slider">
              <OwlCarousel
                className="owl-theme"
                loop={true}
                margin={20}
                dots={false}
                nav={true}
                navText={[`<div class='nav-button owl-prev'><img src="${sliderArrow}"/></div>`, `<div class='nav-button owl-next'><img src="${sliderArrow}"/></div>`]}
                items={3}
                autoplay={true}
                autoplaySpeed={1e3}
                navSpeed={1e3}
                autoplayTimeout={5000}
                transitionStyle="fade"
                responsive={{
                  0: {
                    items: 1,
                    nav: true,
                    dots: false,
                  },

                  600: {
                    items: 2,
                    nav: true,
                    dots: false,
                  },
                  767: {
                    items: 2,
                    nav: true,
                  },
                  1000: {
                    items: 3,
                    nav: true,
                  },
                }}
              >
                <div class="item">
                  <img src={img1} alt="" width="200" height="200" />
                  <div class="nabox"><span>MR. SANJAY BANSAL</span>Chancellor
                    <br />Dev Bhoomi Uttarakhand University</div>
                </div>

                <div class="item">
                  <img src={img2} alt="" width="200" height="200" />
                  <div class="nabox"><span>MR. AMAN BANSAL</span>Vice President
                    <br />Dev Bhoomi Uttarakhand University</div>
                </div>

                <div class="item">
                  <img src={img3} alt="" width="200" height="200" />
                  <div class="nabox"><span>Dr. Preeti Kothiyal</span>Vice-Chancellor
                    <br />Dev Bhoomi Uttarakhand University</div>
                </div>

                <div class="item">
                  <img src={img4} alt="" width="200" height="200" />
                  <div class="nabox"><span>DR. R.K.TRIPATHI</span>Pro Vice Chancellor
                    <br />Dev Bhoomi Uttarakhand University</div>
                </div>

                <div class="item">
                  <img src={img27} alt="" width="200" height="200" />
                  <div class="nabox"><span>Mr.VINOD KUMAR</span>Registrar
                    <br />Dev Bhoomi Uttarakhand University</div>
                </div>

                <div class="item">
                  <img src={img5} alt="" width="200" height="200" />
                  <div class="nabox"><span>DR. A.K. JAISWAL</span>Advisor
                    <br />Dev Bhoomi Uttarakhand University</div>
                </div>

                <div class="item">
                  <img src={img6} alt="" width="200" height="200" />
                  <div class="nabox"><span>MR. R.K. BAJAJ</span>Finance Controller
                    <br />Dev Bhoomi Uttarakhand University</div>
                </div>

                <div class="item">
                  <img src={img8} alt="" width="200" height="200" />
                  <div class="nabox"><span>MR. S. C. SHARMA</span>Registrar
                    <br />Dev Bhoomi Uttarakhand University</div>
                </div>
                <div class="item">
                  <img src="https://f2.leadsquaredcdn.com/t/t20190508183611/content/common/images/sandeep.png" alt="" width="200" height="200" />
                  <div class="nabox"><span>DR. SANDEEP SHARMA</span>Dean Academics & Innovation
                    <br />Dev Bhoomi Uttarakhand University</div>
                </div>

                <div class="item">
                  <img src={img11} alt="" width="200" height="200" />
                  <div class="nabox"><span>MR. SHUBHASHISH GOSWAMI</span>Controller of Examination
                    <br />Dev Bhoomi Uttarakhand University</div>
                </div>

                <div class="item">
                  <img src={img26} alt="" width="200" height="200" />
                  <div class="nabox"><span>Dr. BHASKER PRATAP CHAUDHARY</span>Director, IQAC
                    <br />Dev Bhoomi Uttarakhand University</div>
                </div>

                <div class="item">
                  <img src="https://f2.leadsquaredcdn.com/t/t20190508183611/content/common/images/ritika.png" alt="" width="200" height="200" />
                  <div class="nabox"><span>PROF.(DR.) RITIKA MEHRA</span>Dean - SoEC
                    <br />Dev Bhoomi Uttarakhand University</div>
                </div>

                <div class="item">
                  <img src={img13} alt="" width="200" height="200" />
                  <div class="nabox"><span>Maj GEN (Dr.) O P Soni</span>Dean - SoMC
                    <br />Dev Bhoomi Uttarakhand University</div>
                </div>

                <div class="item">
                  <img src="https://f2.leadsquaredcdn.com/t/t20190508183611/content/common/images/nabeel.png" alt="" width="200" height="200" />
                  <div class="nabox"><span>PROF.(DR.) NABEEL AHMAD</span>Dean, R&D & School of Allied Sciences
                    <br />Dev Bhoomi Uttarakhand University</div>
                </div>

                <div class="item">
                  <img src="https://f2.leadsquaredcdn.com/t/t20190508183611/content/common/images/mehvish.png" alt="" width="200" height="200" />
                  <div class="nabox"><span>PROF.(DR.) MEHVISH KHALID</span>Dean - DBIN
                    <br />Dev Bhoomi Uttarakhand University</div>
                </div>

                <div class="item">
                  <img src="https://f2.leadsquaredcdn.com/t/t20190508183611/content/common/images/mahesh.png" alt="" width="200" height="200" />
                  <div class="nabox"><span>DR. MAHESH UNIYAL</span>Dean - SoHMT
                    <br />Dev Bhoomi Uttarakhand University</div>
                </div>

                <div class="item">
                  <img src={img25} alt="" width="200" height="200" />
                  <div class="nabox"><span>Ar. UDYAN AGGARWAL</span>Dean - School of Architecture, Planning & Design
                    <br />Dev Bhoomi Uttarakhand University</div>
                </div>

                <div class="item">
                  <img src="https://f2.leadsquaredcdn.com/t/t20190508183611/content/common/images/deepa.png" alt="" width="200" height="200" />
                  <div class="nabox"><span>PROF. DEEPA ARYA</span>Dean - SoJLA
                    <br />Dev Bhoomi Uttarakhand University</div>
                </div>

                <div class="item">
                  <img src={img23} alt="" width="200" height="200" />
                  <div class="nabox"><span>DR. Manisha Phogat</span>Dean - SOA
                    <br />Dev Bhoomi Uttarakhand University</div>
                </div>

                <div class="item">
                  <img src={img22} alt="" width="200" height="200" />
                  <div class="nabox"><span>DR.Amit Chowdhary </span>Principle- DBMCAH
                    <br />Dev Bhoomi Uttarakhand University</div>
                </div>

                <div class="item">
                  <img src={img24} alt="" width="200" height="200" />
                  <div class="nabox"><span>Mr. Kaustubh Das</span>Dean - SOAP
                    <br />Dev Bhoomi Uttarakhand University</div>
                </div>

                <div class="item">
                  <img src={img21} alt="" width="200" height="200" />
                  <div class="nabox"><span>Dr. Anamika Saxena</span>Dean - SoPR
                    <br />Dev Bhoomi Uttarakhand University</div>
                </div>

              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FacultSection;
