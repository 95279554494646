import { useContext } from "react";
import { useHistory } from "react-router-dom";
import AuthHandler from "../../utility";
import { GLOBAL_CONTEXT } from "../../routes";
import styles from "./style.module.css";

const Logout = ({children}) => {
  const history = useHistory();
  const GLOBAL_DATA = useContext(GLOBAL_CONTEXT);
  const { setGlobalContext } = GLOBAL_DATA;

  const logoutHandler = () => {
    AuthHandler.ExpireAuthSessionInfoCookie(setGlobalContext);
    window.location.href = ("/");
  }

  return (
    <div className={styles.logoutWrapper}
      onClick={logoutHandler}>
      {children ? children : "Logout"}
    </div>
  )
}


export default Logout;