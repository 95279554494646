import React, { Component } from "react";
import $ from "jquery";
import { IoArrowBackOutline } from "react-icons/io5";
import { ACCOUNT_DETAILS } from "../../../config";
import { LoadingScreen } from "../../../components/Loader";
import "../../../css/platformFormLoader.css";
import "../../../css/platformFormStyles.css";
import "./style.css";
/* global lsq_setupForm */
/* global lsq_setupPortalProcess */

class CourseFeeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullScreenLoader: true,
    };
  }

  loadOverrideCSS = () => {
    this.setState({ fullScreenLoader: false });
  };

  onLSQFormSubmissionSuccess = () => {
    setTimeout(() => {
      const queryParams = window.location.search ?? "";
      window.location.href = `/dashboard/${queryParams}`;
    }, 1500);
  };

  onLSQFormLoadError = (e) => {
    console.log(e);
    const queryParams = window.location.search ?? "";
    window.location.href = `/${queryParams}`;
  };

  onLSQFormSaveFailure = (e) => {
    console.log(e);
    // window.location.href = "/";
  };

  componentDidMount() {
    const globalThis = this;
    const { formsData } = ACCOUNT_DETAILS;
    const { lsqFormUrl, courseFeePaymentForm, lsqFormThemeColor, lsqAuthKey } =
      formsData;
    const { processId } = courseFeePaymentForm;

    // Fetching LeadId & AcitivtyId from URL
    const queryParams = new URLSearchParams(window.location.search);
    const leadId = queryParams.get("leadId");
    const activityId = queryParams.get("activityId");

    try {
      $.getScript(lsqFormUrl)
        .done(function (data) {
          lsq_setupPortalProcess({
            id: processId,
            authKeyProvider: () => lsqAuthKey,
            leadId: leadId ?? "",
            activityId: activityId ?? "",
            templateName: "Text",
            themeOptions: {
              primaryButtonColor: lsqFormThemeColor,
            },
          });
          globalThis.setState({ fullScreenLoader: false });
        })
        .fail(function (error) {
          const queryParams = window.location.search ?? "";
          window.location.href = `/${queryParams}`;
        });
    } catch (error) {
      console.log(error);
    }

    if (window.lsqFormContainer) {
      window.lsqFormContainer.addEventListener(
        "lsqformloadcomplete",
        this.loadOverrideCSS
      );

      window.lsqFormContainer.addEventListener(
        "lsqformsubmissionsuccess",
        this.onLSQFormSubmissionSuccess
      );
      window.lsqFormContainer.addEventListener(
        "LsqFormSaveFailure",
        this.onLSQFormSaveFailure
      );
      window.lsqFormContainer.addEventListener(
        "LSQFormLoadError",
        this.onLSQFormLoadError
      );
    }
  }

  render() {
    const { formsData } = ACCOUNT_DETAILS;
    const {
      lsqAppUrl,
      lsqApiServiceUrl,
      courseFeePaymentForm,
      lsqFormThemeColor,
    } = formsData;
    const { processId } = courseFeePaymentForm;

    return (
      <>
        {this.state.fullScreenLoader && <LoadingScreen />}
        <div className="formParentWrapper courseFeeFormWrapper">
          <div
            onClick={() => (window.location.href = "/dashboard")}
            title="Go Back"
          >
            <span className="formBackButton">
              <IoArrowBackOutline color={lsqFormThemeColor} size={25} />
            </span>
          </div>
          <div
            id="lsq-form-modal"
            data-process-id={processId}
            ref={(elem) => (window.lsqFormContainer = elem)}
            className="modal-v4 fullscreen external lsq-external-form-container"
          >
            <div class="lsq-external-shimmer-container">
              <div class="shimmer-header">
                <div class="shimmer-center">
                  <lines class="shimmer-shine width50"></lines>
                </div>
                <lines class="shimmer-shine"></lines>
              </div>
              <div class="shimmer-body">
                <div class="shimmer-field">
                  <shimmerinput class="shimmer-shine"></shimmerinput>
                  <shimmerlabel class="shimmer-shine"></shimmerlabel>
                </div>
                <div class="shimmer-field">
                  <shimmerinput class="shimmer-shine"></shimmerinput>
                  <shimmerlabel class="shimmer-shine"></shimmerlabel>
                </div>
                <div class="shimmer-field">
                  <shimmerinput class="shimmer-shine"></shimmerinput>
                  <shimmerlabel class="shimmer-shine"></shimmerlabel>
                </div>
                <div class="shimmer-field">
                  <shimmerinput class="shimmer-shine"></shimmerinput>
                  <shimmerlabel class="shimmer-shine"></shimmerlabel>
                </div>
                <div class="shimmer-field">
                  <shimmerinput class="shimmer-shine"></shimmerinput>
                  <shimmerlabel class="shimmer-shine"></shimmerlabel>
                </div>
              </div>
            </div>

            <div className="lsq-form-container-wrapper"></div>

            <div class="lsq-form-hidden-fields">
              <input
                id="lsq-api-service-url"
                name="lsq-api-service-url"
                type="hidden"
                value={lsqApiServiceUrl}
              />

              <input
                id="lsq-app-url"
                name="lsq-app-url"
                type="hidden"
                value={lsqAppUrl}
              />

              <input
                id="lsq-process-id-hidden"
                name="lsq-process-id-hidden"
                type="hidden"
                value={processId}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CourseFeeForm;
