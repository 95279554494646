import rupee from "../../../images/rupee-logo.png";
import icon2 from "../../../images/icon-2.png";
import icon3 from "../../../images/icon-3.png";
import re1 from "../../../images/re-1.png"
import re2 from "../../../images/re-2.png"
import re3 from "../../../images/re-3.png"
import re4 from "../../../images/re-4.png"
import re5 from "../../../images/re-5.png"
import re6 from "../../../images/re-6.png"
import re7 from "../../../images/re-7.png"
import re8 from "../../../images/re-8.png"
import tm1 from "../../../images/testomonials-1.png"
import tm1com from "../../../images/q1tech-logo.png"

import tm3com from "../../../images/Adon-Logo.png"

import tm2com from "../../../images/Decathalon-Logo.png"

import tm4com from "../../../images/Jaro-Education-Logo.png"

import tm5com from "../../../images/Viraj-Ventures-Logo-.png"
import quiter from "../../../images/quiter-btm.png";
import sliderArrow from "../../../images/slider-arrow.png"
import OwlCarousel from "react-owl-carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";

import { useEffect } from "react";
import $ from 'jquery'

const FeatureSection = () => {



    

  return (
    <section className="devsecall devfour">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {" "}
            <div className="allhead text-left">
              <strong>Placements</strong> at DBUU
            </div>
            <div className="theicon">
              <ul>
                <li>
                  <span className="pinkx">
                    <img src={rupee} alt="" />
                  </span>
                  <div className="disbx">
                    <strong>40 LPA</strong>
                    Highest Offered CTC{" "}
                  </div>
                </li>
                <li>
                  <span className="pinkx">
                    <img src={icon2} alt="" />
                  </span>
                  <div className="disbx">
                    <strong>80%</strong>
                    Placement Record{" "}
                  </div>
                </li>
                <li>
                  <span className="pinkx">
                    <img src={icon3} alt="" />
                  </span>
                  <div className="disbx">
                    <strong>850+</strong>
                    Recruiters Across Industries{" "}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row towbc">
          <div className="col-md-12">
            {" "}
            <div className="allhead text-left">
              Global Academic &amp; <strong> Research Collaborations</strong>
            </div>
            <div className="lomaibx">
              <div className="logoitem">
                <img src={re1} alt="" />
                <span>
                  Zhejiang University of Science
                  <br />
                  and Technology (ZUST), China
                </span>
              </div>
              <div className="logoitem">
                <img src={re2} alt="" />
                <span>
                  Biliran Province State University
                  <br />
                  Philippines
                </span>
              </div>
              <div className="logoitem">
                <img src={re3} alt="" />
                <span>
                  Final International University
                  <br />
                  Turkey
                </span>
              </div>
              <div className="logoitem">
                <img src={re4} alt="" />
                <span>
                  Daffodil International University
                  <br />
                  Bangladesh
                </span>
              </div>
              <div className="logoitem">
                <img src={re5} alt="" />
                <span>
                  Business &amp; Hotel Management
                  <br />
                  School (B.H.M.S.), Switzerland
                </span>
              </div>
              <div className="logoitem">
                <img src={re6} alt="" />
                <span>
                  Fashion Design Institut
                  <br />
                  Germany
                </span>
              </div>
              <div className="logoitem">
                <img src={re7} alt="" />
                <span>Youth Education Service (YES)</span>
              </div>
              <div className="logoitem">
                <img src={re8} alt="" />
                <span>Republic Equatorial Guinea</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row testmon">
          <div className="col-md-12">
            <div className="allhead text-left">
              Student and Alumni <strong>Testimonials</strong>
            </div>
            <div className="owl-carousel owl-theme" id="testmonilas">
              <OwlCarousel
                className="owl-theme"
                loop={true}
                margin={20}
                dots={false}
                nav={true}
                navText={[`<div class='nav-button owl-prev'><img src="${sliderArrow}"/></div>`, `<div class='nav-button owl-next'><img src="${sliderArrow}"/></div>`]}
                items={3}
                autoplay={true}
                autoplaySpeed={1e3}
                navSpeed={1e3}
                autoplayTimeout={5000}
                transitionStyle="fade"
                responsive={{
                  0: {
                    items: 1,
                    nav: true,
                    dots: false,
                  },

                  600: {
                    items: 1,
                    nav: true,
                    dots: false,
                  },
                  767: {
                    items: 1,
                    nav: true,
                  },
                  1000: {
                    items: 1,
                    nav: true,
                  },
                }}
              >
                <div className="item">
                  <img
                    src={tm1}
                    alt=""
                    className="testimonimg"
                  />
                  <div className="rightcont">
                    <div className="tesetxt">
                      <img src={quiter} className="quimg top" alt="" />
                      <p>
                        I consider myself immensely lucky to have been a part of DBUU.
                        My two year journey has been a wonderful experience of
                        learning and grooming. It gave me the opportunity to meet
                        people from diverse fields from all over the world. I would
                        like to thank my mentors, and the training and placement
                        department for brilliant opportunities and helping me
                        throughout the journey.
                      </p>
                      <img
                        src={quiter}
                        className="quimg bottomg"
                        alt=""
                      />
                    </div>
                    <div className="nabx">
                      <span>Ritanshi Singh</span>
                      MBA, class of 2023
                    </div>
                    <div className="lonaname">
                      <div className="nambl">Placed at</div>
                      <div className="imboxa">
                        <img src={tm1com} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <img
                    src="https://f2.leadsquaredcdn.com/t/t20190508183611/content/common/images/Meenu-Rani---MBA.png"
                    alt=""
                    className="testimonimg"
                  />
                  <div className="rightcont">
                    <div className="tesetxt">
                      <img src={quiter} className="quimg top" alt="" />
                      <p>
                        One of the finest decisions I have made in my professional
                        life was to go Dev Bhoomi Uttarakhand University to study.
                        From finance to marketing, the MBA programme provided a
                        comprehensive education in all facets of business. I was able
                        to build useful abilities that I could use in practical
                        situations because to the hands-on learning method. By giving
                        me opportunities to network with possible employers and
                        practise for interviews, the university's placement cell also
                        played a significant part in my achievement.{" "}
                      </p>
                      <img
                        src={quiter}
                        className="quimg bottomg"
                        alt=""
                      />
                    </div>
                    <div className="nabx">
                      <span>Meenu Rani</span>
                      MBA, class of 2023
                    </div>
                    <div className="lonaname">
                      <div className="nambl">Placed at</div>
                      <div className="imboxa">
                        <img src={tm2com} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <img
                    src="https://f2.leadsquaredcdn.com/t/t20190508183611/content/common/images/Nitesh-Kumar-MBA.png"
                    alt=""
                    className="testimonimg"
                  />
                  <div className="rightcont">
                    <div className="tesetxt">
                      <img src={quiter} className="quimg top" alt="" />
                      <p>
                        I am honoured to be a Dev Bhoomi Uttarakhand University MBA
                        student and even more honoured to have been hired by Adon
                        Publicity. My excellent business and management background
                        from the university has enabled me to do well throughout my
                        internship atAdon Publicity. The education is both demanding
                        and gratifying, and the teachers are competent and devoted to
                        their students' success. I've had the chance to work on actual
                        business initiatives, hone my leadership and teamwork
                        abilities, and get practical experience in my area of
                        expertise.{" "}
                      </p>
                      <img
                        src={quiter}
                        className="quimg bottomg"
                        alt=""
                      />
                    </div>
                    <div className="nabx">
                      <span>Nitesh Kumar</span>
                      MBA, class of 2023
                    </div>
                    <div className="lonaname">
                      <div className="nambl">Placed at</div>
                      <div className="imboxa">
                        <img src={tm3com} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <img
                    src="https://f2.leadsquaredcdn.com/t/t20190508183611/content/common/images/Soniya-Singh-Chouhan---MBA.png"
                    alt=""
                    className="testimonimg"
                  />
                  <div className="rightcont">
                    <div className="tesetxt">
                      <img src={quiter} className="quimg top" alt="" />
                      <p>
                        Studying at Dev Bhoomi Uttarakhand University has been a
                        transformative experience for me and it wouldn't have been
                        possible without the support of the fantastic faculty and
                        resources available. I am proud to say that I have been placed
                        at Jaro Education through the college's placement cell. The
                        knowledge and skills I acquired during my MBA program have
                        been instrumental in securing this opportunity. I am grateful
                        to DBUU for providing me with a platform to pursue my dreams
                        and launch a successful career. Thank you!
                      </p>
                      <img
                        src={quiter}
                        className="quimg bottomg"
                        alt=""
                      />
                    </div>
                    <div className="nabx">
                      <span>Sonia Singh Chouhan</span>
                      MBA, class of 2023
                    </div>
                    <div className="lonaname">
                      <div className="nambl">Placed at</div>
                      <div className="imboxa">
                        <img src={tm4com} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <img
                    src="https://f2.leadsquaredcdn.com/t/t20190508183611/content/common/images/Akash-Tiwari---MBA.png"
                    alt=""
                    className="testimonimg"
                  />
                  <div className="rightcont">
                    <div className="tesetxt">
                      <img src={quiter} className="quimg top" alt="" />
                      <p>
                        Joining Dev Bhoomi Uttarakhand University for my MBA degree
                        was one of the best decisions I have ever made. The
                        high-quality education and practical exposure provided by the
                        university has prepared me for a successful career in the
                        corporate world. The hands-on training and the opportunities
                        to interact with industry experts has honed my skills and
                        allowed me to reach my full potential. I highly recommend DBUU
                        for anyone seeking a challenging and fulfilling MBA
                        experience.
                      </p>
                      <img
                        src={quiter}
                        className="quimg bottomg"
                        alt=""
                      />
                    </div>
                    <div className="nabx">
                      <span>Akash Tiwari</span>
                      MBA, class of 2023
                    </div>
                    <div className="lonaname">
                      <div className="nambl">Placed at</div>
                      <div className="imboxa">
                        <img src={tm5com} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>{" "}
        </div>
      </div>
    </section>

  );
};

export default FeatureSection;
