import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { GLOBAL_CONTEXT } from "../../routes";
import Button from "../../components/Button";
import DefaultDashboardImage from "../../images/defaultDashboardBG.png"
import Styles from "./style.module.css";

const DefaultDashboard = () => {
  const history = useHistory();
  const GLOBAL_DATA = useContext(GLOBAL_CONTEXT);
  const { globalContext } = GLOBAL_DATA;
  const { leadData } = globalContext;
  const { ProspectID } = leadData;
  const currentYear = new Date().getFullYear();

  return (
    <div className={Styles.defaultDashboard}>
      <div className={Styles.defaultDashboardImg}>
        <img
          src={DefaultDashboardImage}
          alt="Dashboard Banner"
        />
      </div>
      <div className={Styles.defaultDashboardText}>
        <span>
          Start your application for Admission {currentYear}!
        </span>
      </div>
      <Button
        disabled={false}
        type={'button'}
        className={Styles.defaultDashboardButton}
        text={"Apply Now"}
        title={"Apply Now"}
        handleClick={() => { window.location.href = (`/applicationForm?leadId=${ProspectID}`) }}
      />
    </div>
  )
};

export default DefaultDashboard;
