import Timer from "react-compound-timer";
import Input from "../../../components/Input";
import "./style.css";

const OtpFormInput = ({ setResend, setOtp, otp, resend }) => {
  return (
    <>
      <div className="login-otp-screen">
      {resend && 
        <div className="otp-box-wrapper">
          <Input
            type="number"
            handleChange={(value) => {
              setOtp(value);
            }}
            handleBlur={() => {}}
            value={otp}
            name="otp"
            error={false}
            label="Otp"
            mandatory={true}
            placeholder={"Enter 6 Digit Code"}
            className="capitalize"
          />
        </div>}

        <div className="otp-timer">
          {resend !== true ? (
            <div style={{ paddingRight: "5px" }}>Click on Resend OTP</div>
          ) : (
            <>
              <div style={{ paddingRight: "5px" }}>Resend OTP in</div>
              <Timer
                initialTime={120000}
                direction="backward"
                checkpoints={[
                  {
                    time: 0,
                    callback: () => setResend(),
                  },
                ]}
              >
                {() => (
                  <>
                    <Timer.Minutes /> :
                    <Timer.Seconds />
                  </>
                )}
              </Timer>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default OtpFormInput;
