import img1 from "../../../images/Ajay-Rawat.jpg";
import img2 from "../../../images/Harshita.jpg";
import img3 from "../../../images/Dikshant.jpg";
import video1 from "../../../images/video1.jpg";
import video2 from "../../../images/video2.jpg";
import video3 from "../../../images/video3.jpg";
import OwlCarousel from "react-owl-carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { useEffect } from "react";
import $ from 'jquery'

const VideoTestimonialSection = () => {

  

  return (
    <div className="video-testimonial-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <h3>
              SUCCESS <strong>STORIES</strong>
            </h3>
            <div className="student-testimonial text-center">
              <div className="owl-carousel owl-theme" id="testimonial-slider">
                <OwlCarousel
                  className="owl-carousel owl-theme owl-loaded owl-drag"
                  loop={true}
                  margin={0}
                  dots={false}
                  nav={true}
                  items={1}
                  autoplay={true}
                  responsive={{
                    0: {
                      items: 1,
                      nav: true,
                    },

                    600: {
                      items: 1,
                      nav: true,
                    },
                    767: {
                      items: 1,
                      nav: true,
                    },
                  }}
                >
                  <div className="item">
                    <img src={img1} alt="" width="172" height="172" />
                    <p>
                      " One thing that interests me the most about being a
                      DBUU-ian is that we are not bound in our classNamerooms
                      instead every other day we have activities going on
                      including workshops, symposiums, guest lectures by
                      Industry veterans , alumni connecting programs, tech-fests
                      which keeps us engaged throughout the year. Here, the more
                      I study and understand technology, the more I feel that
                      nothing in the universe is out of reach."{" "}
                    </p>
                    <p className="small">
                      <strong>- AJAY RAWAT</strong>
                      <br />
                      B.Tech., Batch 2018 - 2021
                    </p>
                  </div>

                  <div className="item">
                    <img src={img2} alt="" width="172" height="172" />
                    <p>
                      “I am a fashion lover. Modeling was my dream and later I
                      realize that learning about Fashion Industry gives much
                      more to learn. I thank DBUU, Dehradun for making me
                      confront form the truest form of fashion world. There is
                      no limit for creativity, which enhances my talent of
                      fashion & style, day by day. Another thing I love about
                      DBUU is they provide free counseling sessions if needed
                      very good support from tutors and great feedback
                      especially in the clinical years”
                    </p>
                    <p className="small">
                      <strong>- HARSHITA CHOUDHARY</strong>
                      <br />
                      BFD, Batch 2019- 2023
                    </p>
                  </div>

                  <div className="item">
                    <img src={img3} alt="" width="172" height="172" />
                    <p>
                      "Given me to tools to start my carrier. I am so grateful
                      to B.Tech. (CS) for the incredible course. I highly
                      recommend (CS) to anyone wanting a career helping people
                      and one that provides flexibility and incredible job
                      satisfaction. The B.Tech. student union is great as they
                      put on events to support the colleagues and listen to the
                      voices of the student body."
                    </p>
                    <p className="small">
                      <strong>- DIKSHANT GAUTAM</strong>
                      <br />
                      B.Tech., Batch 2019 - 2023
                    </p>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
          <div className="col-md-6 no-padding">
            <div className="videobg" align="center">
              <h3>
                CAMPUS <strong>LIFE</strong>
              </h3>
              <div className="owl-carousel owl-theme" id="video-slider">
                <OwlCarousel
                  className="owl-carousel owl-theme owl-loaded owl-drag"
                  loop={true}
                  margin={0}
                  dots={false}
                  nav={true}
                  items={1}
                  autoplay={true}
                  responsive={{
                    0: {
                      items: 1,
                      nav: true,
                    },

                    600: {
                      items: 1,
                      nav: true,
                    },
                    767: {
                      items: 1,
                      nav: true,
                    },
                  }}
                >
                  <div className="item" align="center">
                    <a
                      className="popup-youtube"
                      data-dismiss="modal"
                      href="https://www.youtube.com/watch?v=7yZZ9aX-hk8"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={video1} />
                    </a>
                  </div>

                  <div className="item" align="center">
                    <a
                      className="popup-youtube"
                      data-dismiss="modal"
                      href="https://www.youtube.com/watch?v=8F_q1mn3brk"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={video2} />
                    </a>
                  </div>

                  <div className="item" align="center">
                    <a
                      className="popup-youtube"
                      data-dismiss="modal"
                      href="https://www.youtube.com/watch?v=5Xp44vxVFXA"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={video3} />
                    </a>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoTestimonialSection;
