import "bootstrap/dist/css/bootstrap.min.css";
import Nav from 'react-bootstrap/Nav';
import { useState } from "react";
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import "./styles.css";
import { NavDropdown } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

const ProgramSection = () => {
  const [open, setOpen] = useState(false);
  const handleClick = (navId) =>
    setOpen((prev) => {
      return { ...prev, [navId]: !prev[navId] };
    });

  return (
    <section className="devsecall devthree">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="allhead strwo">
              CHOOSE YOUR <strong>PROGRAMME</strong>
            </div>
            <div className="d-flex align-items-start">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                <Col sm={3}>
                  <Nav variant="pills" className="nav flex-column nav-pills">
                    <Nav.Item>
                      <Nav.Link eventKey="first">UNDERGRADUATE</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">POSTGRADUATE</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Diploma</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="fourth">DOCTORATE</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <div>
                  <Tab.Content class="tab-content" id="v-pills-tabContent">
                    <Tab.Pane eventKey="first">
                      <div className="boxrow">
                        <div className="clbox">
                          <div className="ondebx">
                            <div className="hebox">
                              School of Engineering &amp; Computing
                            </div>
                            <div className="calspan onecalspan">
                              <Button
                                // onClick={() => setOpen(!open)}
                                onClick={() => handleClick("collapseExample")}
                                aria-expanded={open}
                                aria-controls="collapseExample"
                              // className="btn btn-primary"
                              // type="button"
                              >
                                Bachelor of Technology
                              </Button>
                              <Collapse in={open["collapseExample"]}>
                                <div className="card card-body" id="collapseExample">
                                  <ul>
                                    <li> Computer Science &amp; Engineering </li>
                                    <li> Civil Engineering </li>
                                    <li> Mechanical Engineering</li>
                                    <li>Electronics &amp; Communication Engineering</li>
                                    <li>Electrical Engineering</li>
                                    <li>Aerospace Engineering</li>
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                            <div className="calspan onecalspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample2")}
                                aria-expanded={open}
                                aria-controls="collapseExample2"
                              >
                                Bachelor of Technology CSE with specialization
                              </Button>
                              <Collapse in={open["collapseExample2"]} >
                                <div className="card card-body" id="collapseExample2">
                                  <ul>
                                    <li>
                                      {" "}
                                      Artificial Intelligence &amp; Machine Learning{" "}
                                    </li>
                                    <li> Cyber Security &amp; Forensics </li>
                                    <li>Data Science</li>
                                    <li>Blockchain Technology</li>
                                    <li>Robotics Process Automation</li>
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                            <div className="calspan onecalspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample3")}
                                aria-expanded={open}
                                aria-controls="collapseExample3"
                              >
                                BCA/B.Sc.
                              </Button>
                              <Collapse in={open["collapseExample3"]} >
                                <div className="card card-body" id="collapseExample3">
                                  <ul>
                                    <li> BCA </li>
                                    <li> BCA - Data Science </li>
                                    <li> BCA - Full Stack</li>
                                    <li>B.Sc. - Information Technology</li>
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample7")}
                                aria-expanded={open}
                                aria-controls="collapseExample7"
                              >
                                BCA Cyber Security
                              </Button>
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample7")}
                                aria-expanded={open}
                                aria-controls="collapseExample7"
                              >
                                BCA UX
                              </Button>
                            </div>
                            <div className="hebox">
                              School of Pharmacy &amp; Research
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample4")}
                                aria-expanded={open}
                                aria-controls="collapseExample4"
                              >
                                Bachelor of Pharmacy(B.Pharm)
                              </Button>
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample5")}
                                aria-expanded={open}
                                aria-controls="collapseExample5"
                              >
                                Doctor of Pharmacy(Pharm.D)
                              </Button>

                            </div>
                            <div className="hebox">
                              Dev Bhoomi Medical College of Ayurveda & Hospital
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample6")}
                                aria-expanded={open}
                                aria-controls="collapseExample6"
                              >
                                Bachelor of Ayurvedic Medicine &amp; Surgery
                              </Button>

                            </div>
                            <div className="hebox">
                              Dev Bhoomi Institute of Nursing{" "}
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample7")}
                                aria-expanded={open}
                                aria-controls="collapseExample7"
                              >
                                Bachelor of Science - Nursing
                              </Button>
                            </div>
                            <div className="hebox">
                              {" "}
                              Dev Bhoomi Medical College of Paramedical Sciences
                            </div>
                            <div className="calspan onecalspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample9")}
                                aria-expanded={open}
                                aria-controls="collapseExample9"
                              >
                                Bachelor of Science
                              </Button>
                              <Collapse in={open["collapseExample9"]}>
                                <div className="card card-body" id="collapseExample9">
                                  <ul>
                                    <li> Radiology &amp; Imaging Technology</li>
                                    <li>Operation Theatre Technology (OTT)</li>
                                    <li>Medical Microbiology</li>
                                    <li>Optometry</li>
                                    <li>BMLT - Pathology</li>
                                    <li>BPT - Physiotheraphy</li>
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampleother")}
                                aria-expanded={open}
                                aria-controls="collapseExampleother"
                              >
                                Bachelor of Hospital Administration (BHA)
                              </Button>
                            </div>
                            <div className="hebox">
                              School of Journalism &amp; Liberal Arts
                            </div>
                            <div className="calspan onecalspan ">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExamplead")}
                                aria-expanded={open}
                                aria-controls="collapseExamplead"
                              >
                                Bachelor of Arts
                              </Button>
                              <Collapse in={open["collapseExamplead"]} >
                                <div className="card card-body" id="collapseExamplead">
                                  <ul>
                                    <li>Economics(Hons.)</li>
                                    <li>English(Hons.)</li>
                                    <li>Journalism and Mass Communication(Hons.)</li>
                                    <li>Public Policy And Administration</li>
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampleabva")}
                                aria-expanded={open}
                                aria-controls="collapseExampleabva"
                              >
                                Bachelor of Fine Arts (BFA)
                              </Button>
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampleabva")}
                                aria-expanded={open}
                                aria-controls="collapseExampleabva"
                              >
                                B.Des. Fashion Design
                              </Button>
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampleabva")}
                                aria-expanded={open}
                                aria-controls="collapseExampleabva"
                              >
                                B.Sc. Fashion Design
                              </Button>
                            </div>
                          </div>
                          <div className="ondebx">
                            <div className="hebox">
                              School of Management &amp; Commerce
                            </div>
                            <div className="calspan onecalspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampleaf")}
                                aria-expanded={open}
                                aria-controls="collapseExampleaf"
                              >
                                Bachelor of Business Administration
                              </Button>
                              <Collapse in={open["collapseExampleaf"]}>
                                <div className="card card-body" id="collapseExampleaf">
                                  <ul>
                                    <li>BBA - Business Analytics</li>
                                    <li>BBA - Digital Marketing</li>
                                    <li>BBA - Digital Banking and Fintech</li>
                                    <li>BBA - Aviation and Airport Management</li>
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("eng")}
                                aria-expanded={open}
                                aria-controls="eng"
                              >
                                BBA HR/Marketing/Finance/International Business
                              </Button>

                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("eng")}
                                aria-expanded={open}
                                aria-controls="eng"
                              >
                                B.Com. Hons
                              </Button>

                            </div>
                            <div className="hebox">
                              Dev Bhoomi School of Architecture, Planning &amp; Design
                            </div>
                            <div className="calspan onecalspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampleaee")}
                                aria-expanded={open}
                                aria-controls="collapseExampleaee"
                              >
                                Bachelor of Design
                              </Button>
                              <Collapse className="onecalspan" in={open["collapseExampleaee"]}>
                                <div className="card card-body" id="collapseExampleaee">
                                  <ul>
                                    <li>Product Design</li>
                                    <li> Digital Product Design & UI/UX</li>
                                    <li> Interior Design</li>
                                    <li>Graphic Design</li>
                                    <li>Game Design &amp; Animation</li>
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampleother")}
                                aria-expanded={open}
                                aria-controls="collapseExampleother"
                              >
                                Bachelor of Architecture
                              </Button>
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampleother")}
                                aria-expanded={open}
                                aria-controls="collapseExampleother"
                              >
                                Bachelor of Planning
                              </Button>
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampleother")}
                                aria-expanded={open}
                                aria-controls="collapseExampleother"
                              >
                                Bachelor of Science - Animation & Gaming
                              </Button>
                            </div>
                            <div className="hebox">School of Agriculture</div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampleother")}
                                aria-expanded={open}
                                aria-controls="collapseExampleother"
                              >
                                B.Sc.-Agriculture(Hons.)
                              </Button>
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampleother")}
                                aria-expanded={open}
                                aria-controls="collapseExampleother"
                              >
                                B.Tech - Dairy Technology
                              </Button>

                            </div>


                            <div className="hebox">School of Allied Sciences</div>
                            <div className="calspan onecalspan ">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampleaef")}
                                aria-expanded={open}
                                aria-controls="collapseExampleaef"
                              >
                                Bachelor of Science (Hons.)
                              </Button>
                              <Collapse in={open["collapseExampleaef"]}>
                                <div className="card card-body" id="collapseExampleaef">
                                  <ul>
                                    <li>Forestry</li>
                                    <li>Forensic Science</li>
                                    <li>Food Technology</li>
                                    <li>Biotech</li>
                                    <li>Physics</li>
                                    <li>Mathematics</li>
                                    <li>Chemistry</li>
                                    <li>Zoology</li>
                                    <li>Microbiology</li>
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                            <div className="hebox">
                              School of Hotel Management &amp; Tourism
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampleaeh")}
                                aria-expanded={open}
                                aria-controls="collapseExampleaeh"
                              >

                                Bachelor of Science - Hotel &   Hospitality Management (HHM)
                              </Button>

                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampleaei")}
                                aria-expanded={open}
                                aria-controls="collapseExampleaei"
                              >
                                Bachelor of Hotel Management
                              </Button>

                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampleaei")}
                                aria-expanded={open}
                                aria-controls="collapseExampleaei"
                              >
                                BBA - Tourism and Event Management
                              </Button>

                            </div>

                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div className="boxrow">
                        <div className="clbox">
                          <div className="ondebx">
                            <div className="hebox">
                              School of Engineering &amp; Computing
                            </div>
                            <div className="calspan onecalspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampleav")}
                                aria-expanded={open}
                                aria-controls="collapseExampleav"
                              >
                                Master of Technology
                              </Button>
                              <Collapse in={open["collapseExampleav"]} >
                                <div className="card card-body" id="collapseExampleav">
                                  <ul>
                                    <li>Computer Science &amp; Engineering</li>
                                    <div className="calspan onecalspan">
                                      <Button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() => handleClick("collapseCivilEngineering")}
                                        aria-expanded={open}
                                        aria-controls="collapseCivilEngineering"
                                      >
                                        Civil Engineering
                                      </Button>
                                      <Collapse in={open["collapseCivilEngineering"]} >
                                        <div className="card card-body" id="collapseCivilEngineering">
                                          <ul>
                                            <li>Structural Engineering </li>
                                            <li>GeoTech Engineering</li>
                                            <li>Transportation Engineering</li>
                                          </ul>
                                        </div>
                                      </Collapse>
                                    </div>
                                    <div className="calspan onecalspan">
                                      <Button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() => handleClick("collapseECEEngineering")}
                                        aria-expanded={open}
                                        aria-controls="collapseECEEngineering"
                                      >
                                        Electronics &amp; Communication Engineering
                                      </Button>
                                      <Collapse in={open["collapseECEEngineering"]} >
                                        <div className="card card-body" id="collapseECEEngineering">
                                          <ul>
                                            <li>Digital Communication</li>
                                          </ul>
                                        </div>
                                      </Collapse>
                                    </div>
                                    <div className="calspan onecalspan">
                                      <Button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() => handleClick("collapseMechanicalEngineering")}
                                        aria-expanded={open}
                                        aria-controls="collapseMechanicalEngineering"
                                      >
                                        Mechanical Engineering
                                      </Button>
                                      <Collapse in={open["collapseMechanicalEngineering"]} >
                                        <div className="card card-body" id="collapseMechanicalEngineering">
                                          <ul>
                                            <li>Energy Engineering</li>
                                            <li>Thermal Engineering</li>
                                            <li>Production Engineering</li>
                                          </ul>
                                        </div>
                                      </Collapse>
                                    </div>
                                    <div className="calspan onecalspan">
                                      <Button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() => handleClick("collapseElectricalEngineering")}
                                        aria-expanded={open}
                                        aria-controls="collapseElectricalEngineering"
                                      >
                                        Electrical Engineering
                                      </Button>
                                      <Collapse in={open["collapseElectricalEngineering"]} >
                                        <div className="card card-body" id="collapseElectricalEngineering">
                                          <ul>
                                            <li>Smart Grid</li>
                                          </ul>
                                        </div>
                                      </Collapse>
                                    </div>
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                            <div className="calspan onecalspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExamplemca")}
                                aria-expanded={open}
                                aria-controls="collapseExamplemca"
                              >
                                Master of Computer Application
                              </Button>
                              <Collapse in={open["collapseExamplemca"]}>
                                <div className="card card-body" id="collapseExamplemca">
                                  <ul>
                                    <li>Data Science</li>
                                    <li>Full Stack Devlopment</li>
                                    <li>Dev Ops</li>
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                            <div className="hebox">
                              School of Pharmacy &amp; Research
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampleabva")}
                                aria-expanded={open}
                                aria-controls="collapseExampleabva"
                              >
                                Master of Pharmacy - Pharmaceutical
                              </Button>

                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampleabva")}
                                aria-expanded={open}
                                aria-controls="collapseExampleabva"
                              >
                                Master of Pharmacy - Pharmacology
                              </Button>
                            </div>
                            <div className="hebox">
                              Dev Bhoomi School of Architecture Planning &amp; Design
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampleabva")}
                                aria-expanded={open}
                                aria-controls="collapseExampleabva"
                              >
                                Master of Planning
                              </Button>

                            </div>
                            <div className="hebox">
                              Dev Bhoomi Medical College of Paramedical Sciences
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampleabva")}
                                aria-expanded={open}
                                aria-controls="collapseExampleabva"
                              >
                                MHA
                              </Button>
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampleabva")}
                                aria-expanded={open}
                                aria-controls="collapseExampleabva"
                              >
                                Master of Public Health (MPH)
                              </Button>
                            </div>
                          </div>
                          <div className="ondebx">
                            <div className="hebox">School of Agriculture</div>
                            <div className="calspan onecalspan ">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampledc")}
                                aria-expanded={open}
                                aria-controls="collapseExampledc"
                              >
                                Master of Science Agriculture
                              </Button>
                              <Collapse in={open["collapseExampledc"]} >
                                <div className="card card-body" id="collapseExampledc">
                                  <ul>
                                    <li>Agronomy</li>
                                    <li>Plant Pathology</li>
                                    <li>Genetics &amp; Plant Breeding</li>
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                            <div className="hebox">School of Allied Sciences</div>
                            <div className="calspan onecalspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampledg")}
                                aria-expanded={open}
                                aria-controls="collapseExampledg"
                              >
                                Master of Science
                              </Button>
                              <Collapse in={open["collapseExampledg"]}>
                                <div className="card card-body" id="collapseExampledg">
                                  <ul>
                                    <li>Mathematics</li>
                                    <li>Physics</li>
                                    <li>Environmental Science</li>
                                    <li>Chemistry</li>
                                    <li>Microbiology</li>
                                    <li>Food Technology</li>
                                    <li>Zoology</li>
                                    <li>Biotechnology</li>
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                            <div className="hebox">
                              School of Journalism &amp; Liberal Arts
                            </div>
                            <div className="calspan onecalspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("masterofarts")}
                                aria-expanded={open}
                                aria-controls="masterofarts"
                              >
                                Master of Arts
                              </Button>
                              <Collapse in={open["masterofarts"]} >
                                <div className="card card-body" id="masterofarts">
                                  <ul>
                                    <li>Journalism and Mass Communication</li>
                                    <li>Public Relations &amp; Advertising</li>
                                    <li>Economics</li>
                                    <li>Fashion Design and Merchandising
                                    </li>
                                  </ul>
                                </div>
                              </Collapse>
                            </div>

                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampleabva")}
                                aria-expanded={open}
                                aria-controls="collapseExampleabva"
                              >
                                MA  English
                              </Button>
                            </div>
                            <div className="hebox">
                              School of Management &amp; Commerce
                            </div>
                            <div className="calspan onecalspan ">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("masterofarts2")}
                                aria-expanded={open}
                                aria-controls="masterofarts2"
                              >
                                Master of Business Administration
                              </Button>
                              <Collapse in={open["masterofarts2"]} >
                                <div className="card card-body" id="masterofarts2">
                                  <ul>
                                    <li>Business Analytics</li>
                                    <li>Logistics and Supply Chain Management</li>
                                    <li>Human Resource</li>
                                    <li>Marketing </li>
                                    <li>Finance </li>
                                    <li>International Business</li>
                                    <li>Agribusiness</li>
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("eng")}
                                aria-expanded={open}
                                aria-controls="eng"
                              >
                                MBA Digital Marketing
                              </Button>

                            </div>
                            <div className="hebox">
                              School of Hotel Management &amp; Tourism
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExamplerr")}
                                aria-expanded={open}
                                aria-controls="collapseExamplerr"
                              >
                                Master in Hotel Management
                              </Button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <div className="boxrow">
                        <div className="clbox">
                          <div className="ondebx">
                            <div className="hebox">
                              School of Pharmacy &amp; Research
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("UBTER")}
                                aria-expanded={open}
                                aria-controls="UBTER"
                              >
                                Diploma in Pharmacy (D.Pharm) - UBTER
                              </Button>

                            </div>
                            <div className="hebox">
                              Dev Bhoomi School of Architecture Planning &amp; Design
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("Diplomainarch")}
                                aria-expanded={open}
                                aria-controls="Diplomainarch"
                              >
                                Diploma In Architecture
                              </Button>

                            </div>
                            <div className="hebox">
                              Dev Bhoomi Institute of Nursing
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("UBTER")}
                                aria-expanded={open}
                                aria-controls="UBTER"
                              >
                                GNM
                              </Button>

                            </div>
                          </div>
                          <div className="ondebx">
                            <div className="hebox">
                              School of Hotel Management &amp; Tourism
                            </div>

                            <div className="calspan onecalspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("DHM")}
                                aria-expanded={open}
                                aria-controls="DHM"
                              >
                                Diploma in Hotel Management (DHM)
                              </Button>
                              <Collapse in={open["DHM"]} >
                                <div className="card card-body" id="DHM">
                                  <ul>
                                    <li>DHM - Food and Beverage</li>
                                    <li>DHM - Bakery & Confectionery</li>
                                    <li>DHM - Food Production</li>
                                  </ul>
                                </div>
                              </Collapse>
                            </div>

                            <div className="hebox">
                              Dev Bhoomi Institute of Polytechnic
                            </div>
                            <div className="calspan onecalspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("edweedwds")}
                                aria-expanded={open}
                                aria-controls="edweedwds"
                              >
                                Polytechnic (UBTER Affiliated)
                              </Button>
                              <Collapse in={open["edweedwds"]} >
                                <div className="card card-body" id="edweedwds">
                                  <ul>
                                    <li>Computer Science &amp; Engineering</li>
                                    <li>Civil Engineering</li>
                                    <li>Electrical Engineering</li>
                                    <li>Mechanical – Production Engineering</li>
                                    <li>Mechanical –Automobile Engineering</li>
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">
                      <div className="boxrow">
                        <div className="clbox">
                          <div className="ondebx" style={{ marginRight: '5px', paddingRight: '15px' }}>
                            <div className="hebox">
                              School of Engineering &amp; Computing
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample7")}
                                aria-expanded={open}
                                aria-controls="collapseExample7"
                              >
                                Computer Science &amp; Engineering
                              </Button>
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample7")}
                                aria-expanded={open}
                                aria-controls="collapseExample7"
                              >
                                Mechanical Engineering
                              </Button>
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample7")}
                                aria-expanded={open}
                                aria-controls="collapseExample7"
                              >
                                Electronics &amp; Communication Engineering
                              </Button>
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample7")}
                                aria-expanded={open}
                                aria-controls="collapseExample7"
                              >
                                Computer Science
                              </Button>
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample7")}
                                aria-expanded={open}
                                aria-controls="collapseExample7"
                              >
                                Civil Engineering
                              </Button>
                            </div>
                            <div className="hebox">
                              School of Pharmacy &amp; Research
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample4")}
                                aria-expanded={open}
                                aria-controls="collapseExample4"
                              >
                                Pharmaceutical Sciences
                              </Button>
                            </div>
                            <div className="hebox">
                              School of Allied Sciences
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample6")}
                                aria-expanded={open}
                                aria-controls="collapseExample6"
                              >
                                Maths
                              </Button>
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample6")}
                                aria-expanded={open}
                                aria-controls="collapseExample6"
                              >
                                Physics
                              </Button>
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample6")}
                                aria-expanded={open}
                                aria-controls="collapseExample6"
                              >
                                Chemistry
                              </Button>
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample6")}
                                aria-expanded={open}
                                aria-controls="collapseExample6"
                              >
                                Zoology
                              </Button>
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample6")}
                                aria-expanded={open}
                                aria-controls="collapseExample6"
                              >
                                Microbiology
                              </Button>
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample6")}
                                aria-expanded={open}
                                aria-controls="collapseExample6"
                              >
                                Environmental Science
                              </Button>
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample6")}
                                aria-expanded={open}
                                aria-controls="collapseExample6"
                              >
                                Biotech
                              </Button>
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample6")}
                                aria-expanded={open}
                                aria-controls="collapseExample6"
                              >
                                Food Technology
                              </Button>
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample6")}
                                aria-expanded={open}
                                aria-controls="collapseExample6"
                              >
                                Management
                              </Button>

                            </div>
                          </div>
                          <div className="ondebx">
                            <div className="hebox">
                              School of Management &amp; Commerce
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample7")}
                                aria-expanded={open}
                                aria-controls="collapseExample7"
                              >
                                Management
                              </Button>
                            </div>
                            <div className="hebox">
                              School of Journalism &amp; Liberal Arts
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExample6")}
                                aria-expanded={open}
                                aria-controls="collapseExample6"
                              >
                                Maths
                              </Button>
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("eng")}
                                aria-expanded={open}
                                aria-controls="eng"
                              >
                                Economics
                              </Button>

                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("eng")}
                                aria-expanded={open}
                                aria-controls="eng"
                              >
                                Fine Arts
                              </Button>

                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("eng")}
                                aria-expanded={open}
                                aria-controls="eng"
                              >
                                Fashion Design
                              </Button>

                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("eng")}
                                aria-expanded={open}
                                aria-controls="eng"
                              >
                                English
                              </Button>
                            </div>
                            <div className="hebox">
                              School of Agriculture
                            </div>
                            <div className="calspan">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleClick("collapseExampleother")}
                                aria-expanded={open}
                                aria-controls="collapseExampleother"
                              >
                                Agronomy
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>

              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
};

export default ProgramSection;
