import { useContext } from 'react';
import { GLOBAL_CONTEXT } from '../../../routes';
import phoneIcon from "../../../images/phoneIcon.svg"
import styles from './style.module.css';

const DashboardSubHeader = ({ view }) => {
  const GLOBAL_DATA = useContext(GLOBAL_CONTEXT);
  const { globalContext } = GLOBAL_DATA;
  const { leadData } = globalContext;

  const firstName = leadData?.FirstName;

  return (
    <div className={styles.defaultParentWrapper}>
      <div className={styles.defaultUserGreetingWrapper}>
        {view !== "contactUs"
          ? <>Hello {firstName ?? ""}</>
          : <><img src={phoneIcon} alt="Icon" />Contact Us</>
        }
      </div>
      {view !== "contactUs" &&
        <div className={styles.infoWrapper}>
          Here is your Application History
        </div>}
    </div>
  )
}

export { DashboardSubHeader };
