import axios from "axios";
import { WRAPPER_LAPP_URLS } from "./wrapperLappUrl";
import { V2_ENDPOINTS, PORTAL_SIGN_IN_ENDPOINTS } from "./endpoints";
import { ACCOUNT_DETAILS } from "../config";

const { V2_API_WRAPPER, PORTAL_SIGN_IN_API_WRAPPER, V2_API_BASE_URL } =
  WRAPPER_LAPP_URLS;
const { portalTestUrl, portalLiveUrl } = ACCOUNT_DETAILS;

/*======================================
************ SIGNING API'S ************
=======================================*/

const API_LOGIN = async (payload) => {
  const { params, body } = payload;

  const promise = axios.post(PORTAL_SIGN_IN_API_WRAPPER, {
    method: "POST",
    endpoint: PORTAL_SIGN_IN_ENDPOINTS.SIGN_IN,
    params: params,
    data: body,
  });
  return promise;
};

const API_GET_OTP = async (payload) => {
  const { params, body } = payload;

  const promise = axios.post(PORTAL_SIGN_IN_API_WRAPPER, {
    method: "POST",
    endpoint: PORTAL_SIGN_IN_ENDPOINTS.GET_OTP,
    params: params,
    data: body,
  });
  return promise;
};

const API_RESET_PASSWORD = async (payload) => {
  const { params, body } = payload;

  const promise = axios.post(PORTAL_SIGN_IN_API_WRAPPER, {
    method: "POST",
    endpoint: PORTAL_SIGN_IN_ENDPOINTS.RESET_PASSWORD,
    headers: {
      Referer: portalLiveUrl || portalTestUrl,
    },
    params: params,
    data: body,
  });
  return promise;
};

const API_FORGOT_PASSWORD = async (payload) => {
  const { params, body } = payload;

  const promise = axios.post(PORTAL_SIGN_IN_API_WRAPPER, {
    method: "POST",
    endpoint: PORTAL_SIGN_IN_ENDPOINTS.FORGOT_PASSWORD,
    headers: {
      Referer: portalLiveUrl || portalTestUrl,
    },
    params: params,
    data: body,
  });
  return promise;
};

/*======================================
********** PORTAL DATA API'S **********
=======================================*/

const API_GET_LEAD_DETAILS_BY_ID = async (payload) => {
  const { params, body } = payload;

  const promise = axios.post(V2_API_WRAPPER, {
    method: "GET",
    endpoint: V2_ENDPOINTS.GET_LEAD_BY_ID,
    params: params,
    data: body,
  });
  return promise;
};
const API_GET_LEAD_DETAILS_BY_PHONE = async (payload) => {
  const { params, body } = payload;

  const promise = axios.post(V2_API_WRAPPER, {
    method: "GET",
    endpoint: V2_ENDPOINTS.GET_LEAD_BY_PHONE,
    params: params,
    data: body,
  });
  return promise;
};

const API_UPDATE_LEAD = async (payload) => {
  const { params, body } = payload;

  const promise = axios.post(V2_API_WRAPPER, {
    method: "POST",
    endpoint: V2_ENDPOINTS.UPDATE_LEAD,
    params: params,
    data: body,
  });
  return promise;
};

const API_GET_ACTIVITY_DETAILS = async (payload) => {
  const { params, body } = payload;

  const promise = axios.post(V2_API_WRAPPER, {
    method: "POST",
    endpoint: V2_ENDPOINTS.GET_ACTIVITY_DETAILS_BY_LEAD_ID,
    params: params,
    data: body,
  });
  return promise;
};

const API_GET_LEAD_AUTO_LOGIN = async (payload, authkey) => {
  const headers = {
    Authorization: authkey,
    Referer: portalLiveUrl || portalTestUrl,
  };
  const promise = axios.post(
    V2_API_BASE_URL + PORTAL_SIGN_IN_ENDPOINTS.GET_AUTO_LOGIN_LEAD,
    payload,
    { headers }
  );
  return promise;
};

export {
  API_LOGIN,
  API_GET_OTP,
  API_RESET_PASSWORD,
  API_FORGOT_PASSWORD,
  API_GET_LEAD_DETAILS_BY_ID,
  API_GET_LEAD_DETAILS_BY_PHONE,
  API_UPDATE_LEAD,
  API_GET_ACTIVITY_DETAILS,
  API_GET_LEAD_AUTO_LOGIN,
};
