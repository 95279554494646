import { ACCOUNT_DETAILS } from "../../config"
import lsqLogo from "../../images/lsqLogo.svg";
import styles from "./style.module.css";

const FooterBeforeLogin = () => {
  const { poweredBy } = ACCOUNT_DETAILS;
  return (
    <div className={styles.BL_ParentWrapper}>
      <div className={styles.BL_LogoWrapper}>
        Powered By
        <img src={lsqLogo}
          alt="Powered By LeadSquared"
          title="Powered By LeadSquared"
          onClick={() => { window.open(poweredBy) }}
        />
      </div>
    </div>
  )
}

const FooterAfterLogin = () => {
  const { poweredBy } = ACCOUNT_DETAILS;
  return (
    <div className={styles.AL_ParentWrapper}>
      <div className={styles.AL_LogoWrapper}>
        Powered By
        <img src={lsqLogo}
          alt="Powered By LeadSquared"
          title="Powered By LeadSquared"
          onClick={() => { window.open(poweredBy) }} />
      </div>
    </div>
  )
}

export { FooterBeforeLogin, FooterAfterLogin };