import queryString from "query-string";

const getAuthSessionInfoCookieValue = (propertyName) => {
  let cookieValue = document.cookie.replace(
    /(?:(?:^|.*;\s*)auth-session-info\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
  let val;
  try {
    val = JSON.parse(cookieValue)[propertyName];
  } catch (e) {
    val = {};
  }
  return val;
};

const isAuthSessionInfoCookiePresent = () => {
  const cookie = document.cookie;
  const authenticated = getAuthSessionInfoCookieValue("authSessionState").isAuthenticated;

  if (cookie.indexOf("auth-session-info") >= 0 && authenticated)
    return true;
  else
    return false
};

const AuthHandler = {

  GetLeadId: () => {
    return isAuthSessionInfoCookiePresent()
      ? getAuthSessionInfoCookieValue("authSessionState").leadId
        ? getAuthSessionInfoCookieValue("authSessionState").leadId
        : ""
      : "";
  },

  GetErrorMessage: (errorObj) => {
    return errorObj.response
      ? errorObj.response.data.ExceptionMessage
      : "There was an error processing the request";
  },

  SetCookie: (cookieName, value) => {
    document.cookie = `${cookieName}=${value}; expires=Fri, 31 Dec 9999 23:59:59 GMT;path=/`;
  },
  RemoveCookie: (cookieName) => {
    document.cookie = `${cookieName}=""; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
  },
  SetAuthSessionInfoCookie: (authSessionState, setGlobalContext) => {
    let now = new Date();
    let time = now.getTime();
    const timeout = time + 2 * 1800 * 1000;
    const futureDate = time + 10 * 365 * 24 * 60 * 60;
    const timeSetter = authSessionState.isAuthenticated ? timeout : futureDate;
    now.setTime(timeSetter);

    let utmValues = queryString.parse(window.location.search);
    let utmObj = {
      utm_source_campaign: utmValues.utm_source_campaign,
      utm_source_name: utmValues.utm_source_name,
      utm_source_medium: utmValues.utm_source_medium,
      utm_source_term: utmValues.utm_source_term,
      utm_source_content: utmValues.utm_source_content,
    };
    if (JSON.stringify(utmObj) === JSON.stringify({})) {
      utmObj = getAuthSessionInfoCookieValue("utmObj");
    }
    document.cookie = `auth-session-info=${JSON.stringify({
      authSessionState: authSessionState,
      utmObj: utmObj,
    })};expires=${now.toUTCString()};path=/`;

    setGlobalContext(globalContext => ({
      ...globalContext,
      loggedIn: true
    }))
  },
  GetAuthSessionInfoCookieValue: (propertyName) => {
    return getAuthSessionInfoCookieValue(propertyName);
  },
  ExpireAuthSessionInfoCookie: (setGlobalContext) => {
    document.cookie =
      "auth-session-info=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
    setGlobalContext(globalContext => ({
      ...globalContext,
      loggedIn: false
    }))
  },
  IsAuthSessionInfoCookiePresent: isAuthSessionInfoCookiePresent,
  SaveActiveTabIdInCookie: () => {
    if (document.cookie.indexOf("active-tab-id") >= 0) {
    }
  },
  GetUTMObject: () => {
    return getAuthSessionInfoCookieValue("utmObj");
  },
};

export default AuthHandler;
