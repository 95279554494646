import "antd/dist/antd.css";
import { notification } from "antd";

//type - error,info,success,warning

const showNotification = (description, title, type = "error") => {
  notification[type]({
    message: title || type.toUpperCase(),
    description: description,
    duration: 4,
  });
};

export default showNotification;
