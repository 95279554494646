import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom'
import { API_RESET_PASSWORD } from "../../../api/index";
import { FORM_PLACEHOLDER } from "../../../constants/index";
import { ERROR_MSG } from "../../../constants/index";
import { passwordScheme } from "../../../utility/ValidationSchema";
import Input from "../../../components/Input/index";
import Button from "../../../components/Button/index";
import "./style.css";


const ResetPasswordForm = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formMessage, setFormMessage] = useState("");
  const [apiStatus,setApiStatus] = useState(false);

  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const urlParams = new URLSearchParams(window.location.search);
  const LeadId = urlParams.get('LeadId');
  const TemporaryPassword = urlParams.get('TemporaryPassword');

  const history = useHistory();
    // useEffect(() => {
    //     if(!LeadId && !TemporaryPassword){
    //         history.replace("/")
    //     }  
    // }, [LeadId,TemporaryPassword]);

  const checkFormData = () => {

    if (!passwordScheme(password)) {
      setPasswordError(ERROR_MSG.PASSWORD_PATTERN);
      return false;
    } else {
      setPasswordError("");
    }

    if (!passwordScheme(confirmPassword)) {
      setConfirmPasswordError(ERROR_MSG.PASSWORD_PATTERN);
      return false;
    } else {
      setConfirmPasswordError("");
    }

    if (password != confirmPassword) {
      setFormMessage(ERROR_MSG.PASSWORD_MISMATCH);
      console.log(formMessage,"formM");
      return false;
    } else {
      setFormMessage("");
    }

    return true;
  };

  const onhandleSubmit = () => {
    const validation = checkFormData();
    if (validation) {
      setIsLoading(true);
      let body = {
        LeadId:LeadId ,
        TemporaryPassword: TemporaryPassword,
        Password: password,
      }
      API_RESET_PASSWORD({body})
        .then(function (response) {
          const {data} = response;
          const {message} = data;
          const {Status, ExceptionMessage} = message;
          if(Status === 'Error'){
            setFormMessage(ExceptionMessage);
            setIsLoading(false);
           
          }else{
            setApiStatus(true);
            setFormMessage("Password Changed Successfully");
            setIsLoading(false);
            window.setTimeout(function () {
                history.push("/");
              }, 1500);
          }
         
         
        })
        .catch(function (error) {
          setFormMessage("reset password wrapper API Failed");
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <div className="reset-form-container" style={{paddingTop: "80px"}}>
        <div className="reset-form-headings">
          <p className="reset-title">RESET PASSWORD</p>
          {/* <p className="reset-caption">Please enter your email, We will send a password reset link.</p> */}
        </div>
        {formMessage && <p className={`${apiStatus ? 'form-success-message':'form-fail-message'}`}> {formMessage}</p>}
        <form
          action=""
          method="post"
          className="form-wrapper-box"
          onSubmit={(e) => {
            e.preventDefault();
            onhandleSubmit();
          }}
        >
          <Input
            type="password"
            handleChange={(value) => {
              setPassword(value);
            }}
            handleBlur={() => {}}
            value={password}
            name="Password"
            error={passwordError}
            label="Password"
            mandatory={true}
            placeholder={FORM_PLACEHOLDER.PASSWORD}
          />
          <Input
            type="password"
            handleChange={(value) => {
              setConfirmPassword(value);
            }}
            handleBlur={() => {}}
            value={confirmPassword}
            name="Confirm Password"
            error={confirmPasswordError}
            label="Confirm Password"
            mandatory={true}
            placeholder={FORM_PLACEHOLDER.CONFIRM_PASSWORD}
          />

          <div class="form-group">
            <Button
              // disabled={
              //   password !== confirmPassword ||
              //   password === "" ||
              //   confirmPassword === "" || isLoading
              // }
              type="submit"
              className="btn-primary submit-button"
              text="Reset"
              status={isLoading}
            />
          </div>
          <div className="back-to-login">
            <Button
                disabled={false}
                type="button"
                className="backToLoginBtn"
                text="Back"
                status={false}
                handleClick ={() =>history.push('/')}
              />
          </div>
        </form>
      </div>
      
    </>
  );
};

export default ResetPasswordForm;
