import { useContext, useState, useEffect } from "react";
import { GLOBAL_CONTEXT } from "../../routes";
import { API_GET_ACTIVITY_DETAILS, API_UPDATE_LEAD } from "../../api";
import { ACCOUNT_DETAILS } from "../../config";
import { CardLoader } from "../../components/Loader";
import { DashboardSubHeader } from "../../components/SubHeader/DashboardSubHeader";
import DefaultDashboard from "./defaultDashboard";
import ApplicationCard from "../../components/ApplicationCard";
import styles from "./style.module.css";

const Dashboard = () => {
  /*======================================
    DESTRUCTURING GLOBAL STATE & DEFAULTS 
  =======================================*/

  // Global State Destructuirng
  const GLOBAL_DATA = useContext(GLOBAL_CONTEXT);
  const { globalContext, setGlobalContext } = GLOBAL_DATA;
  const { leadData } = globalContext;
  const { ProspectID, mx_Application_Fee_Paid } = leadData;

  // Activity Details Destructuirng
  const { activityData: activityDetails } = ACCOUNT_DETAILS;
  const { applicationForm } = activityDetails;
  const { eventCode } = applicationForm;

  /*======================================
  *********** STATE MANAGEMENT ***********
  =======================================*/

  const [loader, setLoader] = useState(true);
  const [activityData, setAcitivtyData] = useState([]);
  const [showFallbackUI, setFallbackUI] = useState(false);

  /*======================================
  ************* USE EFFECT's *************
  =======================================*/

  useEffect(() => {
    const payload = {
      params: { leadId: ProspectID },
      body: { Parameter: { ActivityEvent: eventCode } },
    };

    API_GET_ACTIVITY_DETAILS(payload)
      .then((response) => {
        const { data } = response;
        const { message } = data;
        const { ProspectActivities, ExceptionMessage } = message;

        setLoader(false);

        if (ExceptionMessage) setFallbackUI(true);
        else {
          setAcitivtyData(ProspectActivities);
          setGlobalContext({
            ...globalContext,
            activityData: ProspectActivities,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        setFallbackUI(true);
      });
  }, [ProspectID]);

  // Capturning UTM & Updating Source at Temporary Location
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const utmSource = queryParams.get("utm_source") ?? "";
    const utmMedium = queryParams.get("utm_medium") ?? "";
    const payload = {
      params: { leadId: ProspectID },
      body: [],
    };

    if (utmSource) {
      payload.body.push({
        Attribute: "mx_Temporary_Login_Source_Capture",
        Value: utmSource,
      });
    }

    if (utmMedium) {
      payload.body.push({
        Attribute: "mx_Temporary_Source_Medium",
        Value: utmMedium,
      });
    }

    if (!mx_Application_Fee_Paid && payload.body.length > 0) {
      API_UPDATE_LEAD(payload).then(({ data }) => {
        const { message } = data;
        const { Status } = message;
        if (Status === "Success") {
          utmSource
            ? console.log("UTM Name & Medium captured successfully")
            : console.log("UTM Name or Medium is Empty");
          // window.top.history.pushState({}, "", "/dashboard");
        }
      });
    }
  }, [window.location.search]);

  /*======================================
  ************* RETURNING UI ************
  =======================================*/

  if (showFallbackUI) {
    throw new Error("API Call Failed");
  }

  return (
    <>
      <DashboardSubHeader />
      <div className={styles.parentWrapper}>
        {loader && <CardLoader />}
        {!activityData.length && !loader && <DefaultDashboard />}
        {!!activityData.length && (
          <ApplicationCard leadData={leadData} activityData={activityData} />
        )}
      </div>
    </>
  );
};

export default Dashboard;
