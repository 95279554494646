import STATUS_MAPPING, {
  STATUS_MAPPING_PHD,
  STATUS_MAPPING_UG_PG,
} from "./config";
import CARD_THEMES from "./cardTheme";
import styles from "./style.module.css";
import Button from "../../components/Button";

const ApplicationCard = ({ leadData, activityData }) => {
  const [lastActivity] = activityData;
  const { Id: activityId, ActivityFields } = lastActivity;
  const { Status, mx_Custom_1 } = ActivityFields;

  const {
    mx_Course_Level,
    mx_Program_Level,
    mx_courses,
    ProspectID,
    mx_School,
  } = leadData;
  let cardData = {};
  if (
    (leadData && leadData.mx_Program_Level == "Diploma") ||
    leadData.mx_Program_Level == "PG" ||
    leadData.mx_Program_Level == "UG" ||
    leadData.mx_Program_Level == "Pharm.D"
  ) {
    cardData = STATUS_MAPPING_UG_PG(
      Status,
      activityId,
      ProspectID,
      ActivityFields
    );
  } else {
    cardData =
      leadData.mx_Program_Level == "Ph.D" &&
      leadData.mx_Test_Entrance_exam == "DBUU PhD Entrance Exam"
        ? STATUS_MAPPING_PHD(Status, activityId, ProspectID, ActivityFields)
        : STATUS_MAPPING(Status, activityId, ProspectID, ActivityFields);
  }
  const { label, themeColor, actionLinks } = cardData;
  const {
    cardBackground,
    cardLeftBorder,
    cardBorder,
    badgeBackground,
    badgeText,
  } = CARD_THEMES[themeColor];

  return (
    <>
      <div
        className={styles.parentWrapper}
        style={{
          "--cardBackground": cardBackground,
          "--cardBorder": cardBorder,
          "--cardLeftBorder": cardLeftBorder,
        }}
      >
        <div className={styles.cardData}>
          <div className={styles.headingWrapper}>
            <div className={styles.heading}>{mx_Course_Level || "-"}</div>
            <div
              className={styles.badge}
              style={{
                "--badgeBackground": badgeBackground,
                "--badgeText": badgeText,
              }}
            >
              {label}
            </div>
            {(mx_Program_Level === "Diploma" ||
              mx_Program_Level === "PG" ||
              mx_Program_Level === "UG" ||
              mx_Program_Level === "Pharm.D") &&
              (label === "Applied" || label === "Draft- Admission Form") && (
                <div style={{ marginLeft: "30px", color: "red" }}>
                  <span> Note: </span>
                  <span>
                    Registration fee (1500/-) will be refundable, if you pay the
                    admission fee on same date of Registration.
                  </span>
                </div>
              )}
          </div>

          <div className={styles.dataWrapper} style={{ marginTop: "10px" }}>
            <div className={styles.keyValueWrapper}>
              <div className={styles.key}>Application ID</div>
              <div className={styles.value}>{mx_Custom_1 ?? "-"}</div>
            </div>
            <div className={styles.keyValueWrapper}>
              <div className={styles.key}>Program</div>
              <div className={styles.value}>{mx_Program_Level || "-"}</div>
            </div>
            <div className={styles.keyValueWrapper}>
              <div className={styles.key}>Course Interested in</div>
              <div className={styles.value}>{mx_Course_Level || "-"}</div>
            </div>
            <div className={styles.keyValueWrapper}>
              <div className={styles.key}>Branch / Specilization</div>
              <div className={styles.value}>{mx_courses || "-"}</div>
            </div>
          </div>
        </div>

        <div className={styles.linksData}>
          {!!Object.keys(actionLinks).length &&
            Object.keys(actionLinks).map((linkLabel, index) => {
              const { icon, clickHandler, isButton } = actionLinks[linkLabel];
              return isButton ? (
                <Button
                  type={"button"}
                  key={index}
                  className={styles.defaultDashboardButton}
                  handleClick={clickHandler}
                  text={linkLabel}
                  title={linkLabel}
                ></Button>
              ) : (
                <div className={styles.link} key={index}>
                  {icon && <span className={styles.icon}>{icon}</span>}
                  <div className={styles.linkHandler} onClick={clickHandler}>
                    {linkLabel}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default ApplicationCard;
