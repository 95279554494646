import React from "react";
import ReactTelInput from "react-telephone-input";
import MobileCodeInfo from "./MobileCodeInfo";
import { Input as AntdInput } from 'antd';
import Spinner from "react-bootstrap/Spinner";
// import "react-telephone-input/css/default.css";
import "react-telephone-input/lib/withStyles";

import "./style.css";

const Input = ({
  type,
  handleChange,
  handleBlur,
  value,
  name,
  error,
  label,
  mandatory,
  placeholder,
  verified,
  onGenerateOtp,
  sideLink,
  status,
  buttonText,
  className,
  resend
}) => {
  const field = (
    <div className={`form-group ${className}`}>
      <label htmlFor={name}>
        {label} {mandatory && <span>*</span>}
      </label>
      {sideLink && sideLink}
      {
        mandatory ?
          <input
            type={type}
            className={error ? "form-control formError" : "form-control"}
            placeholder={placeholder}
            name={name}
            onChange={(e) => handleChange(e.target.value)}
            onBlur={handleBlur}
            value={value}
            required
          /> :
          <input
            type={type}
            className={error ? "form-control formError" : "form-control"}
            placeholder={placeholder}
            name={name}
            onChange={(e) => handleChange(e.target.value)}
            onBlur={handleBlur}
            value={value}
          />}
      {!!error && <p className="formErrorText">{error}</p>}
    </div>
  );

  const passwordField = (
    <div className={`form-group ${className}`}>
      <label htmlFor={name}>
        {label} {mandatory && <span>*</span>}
      </label>
      {sideLink && sideLink}
      {
        mandatory ?
          <AntdInput.Password
            type={type}
           className={error ? "form-control formError" : "form-control-password"}
            placeholder={placeholder}
            name={name}
            onChange={(e) => handleChange(e.target.value)}
            onBlur={handleBlur}
            value={value}
            required
          /> :
          <AntdInput.Password
            type={type}
            className={error ? "form-control formError" : "form-control-password"}
            placeholder={placeholder}
            name={name}
            onChange={(e) => handleChange(e.target.value)}
            onBlur={handleBlur}
            value={value}
          />}
          <div>
          {!!error && <p className="formErrorText">{error}</p>}
          </div>
      
    </div>
  );

  const emailVerifyField = (
    <div className="form-group">
      <label htmlFor={name}>
        {label} {mandatory && <span>*</span>}
      </label>
      <div className={`verification-wrapper ${verified && "otp-verified"}`}>
        <input
          type="email"
          className={error ? "form-control formError" : "form-control"}
          placeholder={placeholder}
          name={name}
          onChange={(e) => handleChange(e.target.value)}
          onBlur={handleBlur}
          value={value}
        />
        <div className="verification-button">
          {status ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
              <span
                className={`verify-button ${!value ? "disabled" : (resend ? 'disabled' : "")}`} 
                onClick={(e) => {
                  e.preventDefault();
                  onGenerateOtp();
                }}
              >
                Verify
              </span>
            )}
          <p className="otp-success">Verified</p>
        </div>
      </div>
      {error && <p className="formErrorText">{error}</p>}
    </div>
  );

  const phoneVerifyField = (
    <div className="form-group">
      <label htmlFor={name}>
        {label} {mandatory && <span>*</span>}
      </label>
      <div className={`verification-wrapper ${verified && "otp-verified"}`}>
        <ReactTelInput
          defaultCountry="in"
          flagsImagePath="/flags.723494a4.png"
          onChange={(value, selectedCountry) =>
            handleChange(value, selectedCountry)
          }
          onBlur={handleBlur}
        />
        <div className="verification-button">
          {status ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
              <span
                className={`verify-button ${!value ? "disabled" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  onGenerateOtp();
                }}
              >
                Get OTP
              </span>
            )}
          <p className="otp-success">Verified</p>
        </div>
      </div>
      {!!error && <p className="orText">{error}</p>}
    </div>
  );

  const phoneField = (
    <div className="form-group">
      <label htmlFor={name}>
        {label} {mandatory && <span>*</span>}
      </label>
      {/* <ReactTelInput
        defaultCountry="ca"
        flagsImagePath="/flags.723494a4.png"
        onChange={(value, selectedCountry) =>
          handleChange(value, selectedCountry)
        }
        onBlur={handleBlur}
      /> */}
      <MobileCodeInfo
        handleChange={handleChange}
        handleBlur={handleBlur} />
      {!!error && <p className="formErrorText">{error}</p>}
    </div>
  );

  const loginPhoneVerifyField = (
    <div className="form-group">
      <label htmlFor={name}>
        {label} {mandatory && <span>*</span>}
      </label>
      <div className={`verification-wrapper ${verified && "otp-verified"}`}>
        <ReactTelInput
          defaultCountry="in"
          flagsImagePath="/flags.723494a4.png"
          onChange={(value, selectedCountry) =>
            handleChange(value, selectedCountry)
          }
          onBlur={handleBlur}
        />
        <div className="verification-button">
          {status ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
              <span
                className={`verify-button ${!value ? "disabled" : (resend ? 'disabled' : "")}`}
                onClick={(e) => {
                  e.preventDefault();
                  onGenerateOtp();
                }}
              >
                {buttonText}
              </span>
            )}
          <p className="otp-success">Verified</p>
        </div>
      </div>
      {!!error && <p className="formErrorText">{error}</p>}
    </div>
  );


  switch (type) {
    case "text":
    case "email":
    case "number":
      return field;

    case "email-verify":
      return emailVerifyField;

    case "phone":
      return phoneField;

    case "password":
      return passwordField;
    case "phone-verify":
      return phoneVerifyField;
    case "login-otp":
      return loginPhoneVerifyField

    default:
  }
};

export default Input;
