import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Radio } from "antd";
import Input from "../../../components/Input/index";
import Button from "../../../components/Button/index";
import { API_GET_LEAD_DETAILS_BY_ID, API_LOGIN } from "../../../api/index";
import { FORM_PLACEHOLDER } from "../../../constants";
import { ERROR_MSG } from '../../../constants/index';
import "./style.css";
import { CheckCircleTwoTone, InfoCircleFilled } from '@ant-design/icons';
import AuthHandler from "../../../utility";
import { GLOBAL_CONTEXT } from "../../../routes";


const EmailPassword = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [formMessage, setFormMessage] = useState([]);
    const [formSuccessMessage, setFormSuccessMessage] = useState([]);
  
    const history = useHistory();
    // const {signIn} = useAuthentication();
    const GLOBAL_DATA = useContext(GLOBAL_CONTEXT);
  const { setGlobalContext } = GLOBAL_DATA;
    const onSetFormMessageSuccess = (message) => {
      setFormSuccessMessage([message]);
      setFormMessage([]);
  
      setTimeout(() => {
          setFormSuccessMessage([])
      }, 5000);
  }
  
  const onSetFormMessage = (errorType, errorMessage) => {
      // setNewError([{status: errorType, message: errorMessage}])
      setFormMessage([{ status: errorType, message: errorMessage }]);
  }
  
    const onhandleSubmit = () => {
      setIsLoading(true);
  
      API_LOGIN({
        body: {
          EmailAddress: email,
          Password: password,
        },
      })
        .then(async function (response) {
          const { data } = response;
          const { message } = data;
          const { Status, ExceptionMessage, LeadId, AuthKey } = message;
          if (Status === "Error") {
              setIsLoading(false);
              onSetFormMessage("error", ExceptionMessage);
          } else {
              onSetFormMessageSuccess("login successfull.");
            API_GET_LEAD_DETAILS_BY_ID({
              params: {
                  id: response.data.message.LeadId,
              }
          })
              .then(function (res) {
                  // console.log("User Data: ", res.data.message);
                  let leadName = `${res.data.message[0].FirstName} ${res.data.message[0].LastName === null ? "" : res.data.message[0].LastName}`
                  const authSessionInfo = {
                      isAuthenticated: true,
                      leadId: LeadId,
                      authKey: AuthKey,
                  };
  
                  AuthHandler.SetAuthSessionInfoCookie(authSessionInfo, setGlobalContext);
                const queryParams = window.location.search ?? "";
                // history.push("/dashboard");
                window.location.href = `/dashboard/${queryParams}`;
              })
              .catch(function (error) {
                  // console.log(error, "error");
              });
          }
        })
        .catch(function (error) {
          setIsLoading(false);
          onSetFormMessage("error", error.response.data.ExceptionMessage)
        });
    };
  
  
    return (
      <>
        <div className="">
          {/* {formMessage && <p className="form-message"> {formMessage}</p>} */}
          {formMessage &&
                  formMessage.map((item) => {
                      return <Alert
                          description={item.message}
                          type="error"
                          style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "8px",
                              marginBottom: "10px",
                              color: "var(--danger-color-1)",
                              border: "1px solid #ff2c44",
                              background: "#ffcccc",
                              fontWeight: 600,
                              fontSize: "14px"
                          }}
                          icon={<InfoCircleFilled twoToneColor="#FF3939" style={{ fontSize: "16px", marginRight: "7px" }} />}
                          showIcon
                          closable
                      />
                  })
              }
  
              {formSuccessMessage &&
                  formSuccessMessage.map((item) => {
                      return <Alert
                          type="success"
                          style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "8px",
                              marginBottom: "10px",
                              borderRadius: "4px"
                          }}
                          icon={<CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: "16px", marginRight: "7px" }} />}
                          showIcon
                          description={item}
                          closable
                      />
                  })}
  
          <div className="login-email">
            <form
              action=""
              method="post"
              className="form-wrapper-box"
              onSubmit={(e) => {
                e.preventDefault();
                onhandleSubmit();
              }}
            >
              <Input
                type="email"
                handleChange={(value) => {
                  setEmail(value);
                }}
                handleBlur={() => {}}
                value={email}
                name="Email"
                error={false}
                label="Email"
                mandatory={true}
                placeholder={FORM_PLACEHOLDER.EMAIL}
              />
  
              <Input
                type="password"
                handleChange={(value) => {
                  setPassword(value);
                }}
                handleBlur={() => {}}
                value={password}
                name="Password"
                error={false}
                label="Password"
                mandatory={true}
                placeholder={FORM_PLACEHOLDER.PASSWORD}
              />
              <div className="form-group">
                <Button
                  disabled={!email || !password || isLoading}
                  type="submit"
                  className="btn-primary submit-button"
                  text="Sign In"
                  status={isLoading}
                />
              </div>
              <div className="forgot-password">
                <Button
                  disabled={false}
                  type="button"
                  className="forgotBtn"
                  text="Forgot Password?"
                  status={false}
                  handleClick={() => window.location.href = `/forgotpassword`}
                />
              </div>
            </form>
          </div>
        </div>
      </>
    );
  };
  
  export default EmailPassword;
  
